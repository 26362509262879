/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  activeSubscriptionServiceAPI,
  postSubscriptionUpdateAPI,
  userAccountServiceAPI,
} from '../Services/subscriptionsService'
import { SubscriptionsActions as actions } from './subscriptions.slice'
import { ProcessingStatus } from 'app/shared/models/models'
import {
  ActiveSubscriptionAPI,
  NewSubscriptionDataObj,
  Render_RowTypes,
  UserAccountsResponse,
} from '../Model'
import { PayloadAction } from '@reduxjs/toolkit'
import { customerOfBrokerSelected } from 'app/components/CustomerSearch/Slice/customerSearch.selector'
import { CustomerOfBroker } from 'app/components/CustomerSearch/Model'
import { selecteUserIsBroker } from 'app/authorization/Slice/auth-selectors'

export function* getUserAccounts() {
  try {
    const isBroker = yield select(selecteUserIsBroker)
    let data: any
    if (isBroker) {
      const { id }: CustomerOfBroker = yield select(customerOfBrokerSelected)
      data = yield call(userAccountServiceAPI, id)
    } else {
      data = yield call(userAccountServiceAPI)
    }
    const accountsData: UserAccountsResponse = data.data
    if (accountsData && accountsData.isSuccess) {
      yield put(actions.saveUserAccounts(accountsData))
      yield put(actions.subscriptionsError(null))
      //yield put(actions.saveUserAccounts(accountsData))
    } else {
      yield put(actions.subscriptionsError(accountsData.errorMessage))
    }
  } catch (err) {
    yield put(actions.subscriptionsError('An issue occurred retrieving account data.'))
  }
}

export function* getSubscriptionData() {
  try {
    const isBroker = yield select(selecteUserIsBroker)
    let activeSubscription: ActiveSubscriptionAPI
    if (isBroker) {
      const { id }: CustomerOfBroker = yield select(customerOfBrokerSelected)
      activeSubscription = yield call(activeSubscriptionServiceAPI, id)
    } else {
      activeSubscription = yield call(activeSubscriptionServiceAPI)
    }
    activeSubscription.software.forEach(s => {
      s.dataType = Render_RowTypes.DEFAULT_ROW
      s.isCurrentSubscription ? (s.isCurrentStatus = true) : (s.isCurrentStatus = false)
    })

    yield put(actions.saveSoftwareSubscriptionData(activeSubscription))
  } catch (err) {
    yield put(actions.subscriptionsError())
  }
}

export function* saveNewSubscriptionChange(action: PayloadAction<NewSubscriptionDataObj>) {
  try {
    const response: any = yield call(postSubscriptionUpdateAPI, action.payload)
    if (response.status === 200) {
      if (response?.data.isSuccess) {
        yield put(actions.newSubscriptionProcessingStatus(ProcessingStatus.COMPLETED))
      } else {
        yield put(actions.newSubscriptionProcessingStatus(ProcessingStatus.ERROR))
        yield put(actions.subscriptionsError(response?.data.errorMessage))
      }
    } else {
      yield put(actions.newSubscriptionProcessingStatus(ProcessingStatus.ERROR))
      console.log('Error occurred in processing subscription change: ', response.status)
    }
  } catch (err) {
    yield put(actions.newSubscriptionProcessingStatus(ProcessingStatus.ERROR))
    yield put(actions.subscriptionsError())
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* subscriptionsSaga() {
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.getSubscriptionData.type, getSubscriptionData)
  yield takeLatest(actions.getUserAccounts.type, getUserAccounts)
  yield takeLatest(actions.saveNewSubscriptionChange.type, saveNewSubscriptionChange)
}

import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../../root-state'
import { initialState } from '../Model/contactSupport.model'

const selectDomain = (state: RootState) => state.contactSupport || initialState

export const contactSupportModalState = createSelector(
  [selectDomain],
  data => data.showCustomerSupportModal
)

export const contactSupportState = createSelector([selectDomain], state => state)
export const getSupportTicketResponse = createSelector([selectDomain], data => data.ticketData)
export const getSupportTicketStatus = createSelector(
  [selectDomain],
  data => data.contactSupportStatus
)

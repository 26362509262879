import { PayloadAction } from '@reduxjs/toolkit'
import { CustomerListFetchStatus, CustomerOfBroker, initialState } from '../Model'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'
import { customerListSaga } from './customerSearch.saga'
import { createSlice } from 'store/@reduxjs/toolkit'

const slice = createSlice({
  name: 'customerOfBroker',
  initialState,
  reducers: {
    getCustomerListLoad(state) {
      state.customerListStatus = CustomerListFetchStatus.Loading
    },
    getCustomerListLoaded(state, action: PayloadAction<CustomerOfBroker[]>) {
      state.customerList = action.payload
      state.customerListStatus = CustomerListFetchStatus.Success
    },
    getCustomerListError(state) {
      state.customerList = []
      state.customerListStatus = CustomerListFetchStatus.Error
    },
    customerOfBrokerSelected(state, action: PayloadAction<CustomerOfBroker>) {
      state.selctedCustomer = action.payload
    },
  },
})

export const { actions: customerListActions, reducer } = slice

export const useCustomerListSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  useInjectSaga({ key: slice.name, saga: customerListSaga })
  return { actions: slice.actions }
}

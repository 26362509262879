import ApiService from 'app/components/ProfileManagement/Helper/api-service'
import {
  ACCOUNTS_API,
  GET_CURRENT_CREDIT_CARD,
  GET_IS_CERTIFIED,
  GET_MARKET_DATA,
  GET_SUBMIT_PROFESSIONAL_ID,
  POST_BILLING,
  POST_SAVE_MARKET_DATA_ENTITLEMENTS,
} from 'app/components/ProfileManagement/Helper/constants'
import {
  CurrentBillingResponse,
  ExchangePageResponse,
  MarketDataSaveBillingBody,
  SaveBillingResponse,
  SaveMarketDataEntitlementsBody,
  SaveMarketDataEntitlementsResponse,
  UserCertType,
  UserCertifiedResponse,
  UserCertifiedUpdateResponse,
} from '../Model'
import { UserAccountsResponse } from 'app/components/SoftwareSubscriptions/Model/subscriptions.model'

export const getUserCertfiedAPI = async (): Promise<UserCertifiedResponse> => {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const ENDPOINT = `${BASE_URL}${GET_IS_CERTIFIED}`
  const response = await ApiService.get(ENDPOINT).catch(err => {
    throw err
  })

  return response.data
}

export const submitUserCertfiedAPI = async (
  userType: UserCertType
): Promise<UserCertifiedUpdateResponse> => {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const ENDPOINT = `${BASE_URL}${GET_SUBMIT_PROFESSIONAL_ID}?professionalId=${userType}`
  const response = await ApiService.get(ENDPOINT).catch(err => {
    throw err
  })
  return response.data
}

export const getExchangePageAPI = async (customerId?: number): Promise<ExchangePageResponse> => {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const custId = customerId ? `?customerId=${customerId}` : ''
  const ENDPOINT = `${BASE_URL}${GET_MARKET_DATA}${custId}`
  const response = await ApiService.get(ENDPOINT).catch(err => {
    throw err
  })
  return response
}

export const getCurrentBillingAPI = async (): Promise<CurrentBillingResponse> => {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const ENDPOINT = `${BASE_URL}${GET_CURRENT_CREDIT_CARD}`
  const response = await ApiService.get(ENDPOINT).catch(err => {
    throw err
  })
  return response
}

export const postSaveBillingAPI = async (
  SaveBillingBody: MarketDataSaveBillingBody
): Promise<SaveBillingResponse> => {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const ENDPOINT = `${BASE_URL}${POST_BILLING}`
  const response = await ApiService.post(ENDPOINT, SaveBillingBody)
  return response
}

export const sumbitSaveMarketDataEntitlementsAPI = async (
  SaveDataBody: SaveMarketDataEntitlementsBody
): Promise<SaveMarketDataEntitlementsResponse> => {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const ENDPOINT = `${BASE_URL}${POST_SAVE_MARKET_DATA_ENTITLEMENTS}`
  const response = await ApiService.post(ENDPOINT, SaveDataBody)
  return response
}

export const getUserLiveAccountsAPI = async (): Promise<UserAccountsResponse> => {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const ENDPOINT = `${BASE_URL}${ACCOUNTS_API}`
  const accountsDataRes: UserAccountsResponse = await ApiService.get(ENDPOINT).then(res => res.data)
  return accountsDataRes
}

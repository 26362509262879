import { GridEntitlementKeyMap, GridEntitlementsRows, InitialPriceSetHelper } from '../Model'

export const getEntitledPriceSum = (gridRows: GridEntitlementsRows[]) => {
  const result = gridRows.reduce((p, c) => {
    const keyData = c.rowData.map(x => x)
    keyData.forEach(x => {
      if (x.entitled) {
        p = p + x.price
      }
    })
    return p
  }, 0)
  return result
}

export const highestEntitledPrice = (rowData: GridEntitlementKeyMap[]) => {
  const result = rowData.reduce((p, c) => {
    if (c.entitled && c.price > p) {
      return c.price
    }
    return p
  }, 0)
  return result
}

export const getPriceHelperData = (
  rowData: GridEntitlementKeyMap[],
  highPriceBenchmark: number,
  rowLength: number,
  isPro: boolean
) => {
  const result = rowData.reduce((a: InitialPriceSetHelper[], c) => {
    const match = a.findIndex(x => x.licenseNum === c.licenseId)
    if (match === -1) {
      const colAccumulator: InitialPriceSetHelper = {
        licenseNum: c.licenseId,
        numOfEntitled: c.entitled ? 1 : 0,
        entitledPriceSum: c.entitled ? c.price : 0,
        highestEntitledExchPrice: highPriceBenchmark,
        entitledExchIds: c.entitled ? [c.exchangeId] : [],
        defaultRowPrice: c.price,
        defualtBundlePrice: c.bundlePrice,
        entitledNumBenchmark: isPro ? rowLength - 1 : rowLength - 2,
        isProfessional: isPro,
      }
      a.push(colAccumulator)
    } else {
      if (c.entitled) {
        a[match].numOfEntitled++
        a[match].entitledExchIds.push(c.exchangeId)
        a[match].entitledPriceSum =
          a[match].entitledExchIds.length <= a[match].entitledNumBenchmark
            ? a[match].entitledPriceSum + a[match].defaultRowPrice
            : a[match].entitledPriceSum
      }
    }
    return a
  }, [])
  return result
}

export const setUpdatedRowPrice = (
  rowData: GridEntitlementKeyMap[],
  priceHelper: InitialPriceSetHelper[]
) => {
  const result = rowData.reduce((a, c) => {
    const newRowData = { ...c }
    if (c.entitled) {
      newRowData.adjustedPrice = 0
      a.push(newRowData)
      return a
    }
    const licenseMatch = priceHelper.find(x => x.licenseNum === c.licenseId)
    const licenseToCompare = priceHelper.find(x => x.licenseNum !== c.licenseId)
    if (licenseMatch) {
      if (licenseMatch.entitledExchIds.length >= licenseMatch.entitledNumBenchmark) {
        newRowData.adjustedPrice = 0
      } else if (licenseMatch.highestEntitledExchPrice > licenseMatch.defaultRowPrice) {
        newRowData.adjustedPrice = 0
      } else if (
        licenseToCompare &&
        licenseToCompare.entitledExchIds.includes(c.exchangeId) &&
        licenseMatch.defaultRowPrice > licenseToCompare.defaultRowPrice
      ) {
        newRowData.adjustedPrice = licenseMatch.defaultRowPrice - licenseToCompare.defaultRowPrice
      }
      if (licenseToCompare) {
        const adjBudlePrice =
          licenseMatch.defualtBundlePrice -
          licenseMatch.entitledPriceSum -
          licenseToCompare.entitledPriceSum
        newRowData.adjustedBundlePrice = adjBudlePrice > 0 ? adjBudlePrice : 0
        if (c.exchangeId === -1) {
          newRowData.adjustedPrice = adjBudlePrice > 0 ? adjBudlePrice : 0
        }
      }
    }
    a.push(newRowData)
    return a
  }, [])
  return result
}

export const setRowsWithInitialPricingValues = (rows: GridEntitlementsRows[]) => {
  const flatRowData = rows.flatMap(x => x.rowData)
  const highPriceBenchmark = highestEntitledPrice(rows.flatMap(x => x.rowData))
  const isPro = rows.some(x => x.isProfessional)
  const priceHelper = getPriceHelperData(flatRowData, highPriceBenchmark, rows.length, isPro)

  const updateRowData = setUpdatedRowPrice(flatRowData, priceHelper)

  const result = rows.map(row => {
    const updatedRow = { ...row }
    updatedRow.rowData = []
    updateRowData.forEach(obj => {
      if (obj.exchangeId === updatedRow.exchangeId) {
        updatedRow.rowData.push(obj)
      }
    })
    return updatedRow
  })
  return result
}

import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { ProcessingStatus } from 'app/shared/models/models'
import { MenuItem, Select } from '@mui/material'
import {
  selectUserAccountsData,
  selectUserAccountsDataFetchStatus,
} from 'app/components/SoftwareSubscriptions/Slice/subscriptions.selectors'
import { UserAccounts } from 'app/components/SoftwareSubscriptions/Model'

interface SupportSearchProps {
  getSelectedUserAccount: (selectedAccount: UserAccounts) => void
}

export const SupportSearch: React.FC<SupportSearchProps> = ({ getSelectedUserAccount }) => {
  const [userAccountSelected, setUserAccountSelected] = useState<UserAccounts>()
  const userAccounts = useSelector(selectUserAccountsData)
  const userAccountsFetchStatus = useSelector(selectUserAccountsDataFetchStatus)
  const HandleAccountChange = e => {
    const account = userAccounts.accounts?.find(x => x.accountNumber === e.target.value)
    getSelectedUserAccount(account)
    setUserAccountSelected(account)
  }
  const setInitialValue = () => {
    getSelectedUserAccount(userAccounts[0])
    return userAccounts[0]?.accountNumber
  }
  return userAccountsFetchStatus === ProcessingStatus.COMPLETED ? (
    <Select
      MenuProps={{ disableEnforceFocus: true }}
      onClick={e => e.preventDefault()}
      sx={{
        height: '34px',
        width: '250px',
        textAlign: 'left',
        fontFamily: 'Mulish !important',
        fontSize: '14px',
        fontVariationSettings: '"wght" 400',
      }}
      value={userAccountSelected ? userAccountSelected.accountNumber : setInitialValue()}
      onChange={HandleAccountChange}
    >
      {userAccounts &&
        userAccounts.accounts?.map(account => {
          return (
            <MenuItem
              sx={{
                textAlign: 'left',
                fontFamily: 'Mulish !important',
                fontSize: '14px',
                fontVariationSettings: '"wght" 400',
              }}
              key={account.id}
              value={account.accountNumber}
            >
              {`${account.accountNumber}`}
            </MenuItem>
          )
        })}
    </Select>
  ) : (
    <></>
  )
}

import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RouteQueryParams } from './monoMessaging.model'
import { initialState } from './monoMessaging.state'
import { useInjectReducer } from 'store/redux-injectors'

const slice = createSlice({
  name: 'monoMessagingIntegration',
  initialState,
  reducers: {
    updateQueryParams(state, action: PayloadAction<RouteQueryParams>) {
      state.queryParams = { ...state.queryParams, ...action.payload }
    },
    updateMonoWidgetId(state, action: PayloadAction<string>) {
      state.monoWidgetId = action.payload
    },
    updateMonoAppId(state, action: PayloadAction<string>) {
      state.monoAppId = action.payload
    },
  },
})

export const { actions: monoMessagingIntegrationActions, reducer } = slice

export const useMonoMessagingIntegrationSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  return { actions: slice.actions }
}

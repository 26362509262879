import React from 'react'
import '@dotlottie/player-component'
import gradientData from './loader-gradient.json'

import styles from './spinner.module.scss'
export interface SpinnerProps {
  customWidth?: number
  customHeight?: number
}

export const Spinner = ({ customWidth, customHeight }: SpinnerProps): JSX.Element => {
  const spinnerWidth = customWidth || 96
  const spinnerHeight = customHeight || 96
  return (
    <div data-testid={'spinner-container'} className={styles['gradient-wrapper']}>
      <dotlottie-player
        src={JSON.stringify(gradientData)}
        autoplay
        loop
        style={{
          height: `${spinnerHeight}px`,
          width: `${spinnerWidth}px`,
        }}
        speed={'1'}
      />
    </div>
  )
}

export default Spinner

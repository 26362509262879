/* eslint-disable prettier/prettier */
import { Country, ProcessingStatus, State } from 'app/shared/models/models'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'
import {
  Account,
  AccountAddress,
  AccountDetailsUpdates,
  Address,
  PersonalInformation,
  ShowEditAccountAddressPage,
  UpdateContactAccountRequest,
  initialState,
} from '../Model/personalInfo.model'
import { PayloadAction } from '@reduxjs/toolkit'
import { personalInfoSaga } from './personalInfo.saga'
import { createSlice } from 'store/@reduxjs/toolkit'

const slice = createSlice({
  name: 'personalInfo',
  initialState,
  reducers: {
    getStates(state, action: PayloadAction<number>) {
      state.stateFetchStatus = ProcessingStatus.PENDING
    },
    statesLoaded(state, action: PayloadAction<State[]>) {
      state.states = action.payload
      state.stateFetchStatus = ProcessingStatus.COMPLETED
    },
    statesError(state, action: PayloadAction<State[] | null>) {
      state.states = action.payload
      state.stateFetchStatus = ProcessingStatus.ERROR
    },
    getCountries(state, action: PayloadAction<unknown>) {
      state.countriesFetchStatus = ProcessingStatus.PENDING
    },
    countriesLoaded(state, action: PayloadAction<Country[]>) {
      state.countries = action.payload
      state.countriesFetchStatus = ProcessingStatus.COMPLETED
    },
    countriesError(state, action: PayloadAction<Country[] | null>) {
      state.countries = action.payload
      state.countriesFetchStatus = ProcessingStatus.ERROR
    },
    getPersonalDetailInfo(state) {
      state.personalInfoFetchStatus = ProcessingStatus.PENDING
    },
    getPersonalInfoLoaded(state, action: PayloadAction<PersonalInformation>) {
      state.personalInfoFetchStatus = ProcessingStatus.COMPLETED
      state.personalInfo = action.payload
    },
    getPersonalInfoError(state, action: PayloadAction<PersonalInformation | null>) {
      state.personalInfoFetchStatus = ProcessingStatus.ERROR
      state.personalInfo = action.payload
    },
    setAddressesToUpdate(state, action: PayloadAction<Address[]>) {
      state.addressesToUpdate = action.payload
    },
    savePersonalInfo(state, action: PayloadAction<UpdateContactAccountRequest>) {
      state.personalInfoSaveStatus = ProcessingStatus.PENDING
    },
    setPersonalInfoPostData(state, action: PayloadAction<UpdateContactAccountRequest>) {
      state.personPostData = action.payload
    },
    personalInfoProcessingComplete(state, action: PayloadAction<boolean>) {
      state.personalInfoSaveStatus = ProcessingStatus.COMPLETED
      state.personPostData = null
    },
    personalInfoProcessingReset(state) {
      state.personalInfoSaveStatus = ProcessingStatus.RESET
      state.personPostData = null
    },
    personalInfoProcessingError(state, action: PayloadAction<string>) {
      state.personalInfoSaveStatus = ProcessingStatus.ERROR
      state.savePersonalInfoErrorResponse = action.payload
    },
    saveAccountAddresses(state, action: PayloadAction<UpdateContactAccountRequest>) {
      state.accountAddressSaveStatus = ProcessingStatus.PENDING
    },
    setAccountAddressesUpdateData(state, action: PayloadAction<UpdateContactAccountRequest>) {
      state.accountAddressPostData = action.payload
    },
    updateAccountAddressessProcessingComplete(state, action: PayloadAction<boolean>) {
      state.accountAddressSaveStatus = ProcessingStatus.COMPLETED
      state.accountAddressPostData = null
    },
    AccountAddressesUpdateReset(state) {
      state.accountAddressSaveStatus = ProcessingStatus.RESET
      state.accountAddressPostData = null
    },
    AccountAddressesProcessingError(state, action: PayloadAction<string>) {
      state.accountAddressSaveStatus = ProcessingStatus.ERROR
      state.saveAccountAddressErrorResponse = action.payload
    },
    getAccountDetails(state, action: PayloadAction<string[]>) {
      state.accountsFetchStatus = ProcessingStatus.PENDING
    },
    getAccountDetailsLoaded(state, action: PayloadAction<Account[]>) {
      state.accounts = action.payload
      state.accountsFetchStatus = ProcessingStatus.COMPLETED
    },
    getAccountsError(state, action: PayloadAction<Account[] | null>) {
      state.accounts = action.payload
      state.accountsFetchStatus = ProcessingStatus.ERROR
    },
    getAccountAddresses(state, action: PayloadAction<Account>) {
      state.accountAddressFetchStatus = ProcessingStatus.PENDING
    },
    getAccountAddressesLoaded(state, action: PayloadAction<AccountAddress>) {
      state.accountAddresses.push(action.payload)

      state.accountAddressFetchStatus = ProcessingStatus.COMPLETED
    },
    getAccountAddressesError(state) {
      state.accountAddressFetchStatus = ProcessingStatus.ERROR
    },
    setAccountToUpdate(state, action: PayloadAction<Account>) {
      state.accountToUpdate = action.payload
    },
    setSelectedAccount(state, action: PayloadAction<Account>) {
      state.selectedAccount = action.payload
    },
    showEditAccountAddressPage(state, action: PayloadAction<ShowEditAccountAddressPage>) {
      state.showEditAccountAddressPage = action.payload
    },
    showEditPersonalInfoPage(state, action: PayloadAction<boolean>) {
      state.showEditPersonalnfoPage = action.payload
    },
    getAccountDetailsUpdate(state, action: PayloadAction<string>) {
      state.accountDetailsUpdate = []
      state.accountDetailsFetch = ProcessingStatus.PENDING
    },
    setAccountDetailsUpdate(state, action: PayloadAction<AccountDetailsUpdates[]>) {
      const value = action.payload
      state.accountDetailsUpdate = value
      state.accountDetailsFetch = ProcessingStatus.COMPLETED
    },
    putAccountDetailsUpdate(state, action: PayloadAction<AccountDetailsUpdates>){
      const temp: AccountDetailsUpdates[] = state.accountDetailsUpdate
      temp.unshift(action.payload)
      state.accountDetailsUpdate = temp
    },
    errorAccountDetailsUpdate(state) {
      state.accountDetailsFetch = ProcessingStatus.ERROR
    },
  },
})

export const { actions: personalInfoActions, reducer } = slice

export const usePersonalInfoSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  useInjectSaga({ key: slice.name, saga: personalInfoSaga })
  return { actions: slice.actions }
}

export interface ThirdPartyAccessType {
  userType: string
  customerEmails?: string[]
}

export interface ThirdPartyData {
  signUpUrl: string
  userName: string
  customerStatus: boolean
  liveHelpUrl?: string
  subs?: SoftwareSubs[]
}

export interface SoftwareSubs {
  id: number
  name: string
  setupUrl: string
  demoUrl: string
  liveUrl: string
  isActive: boolean
  isLive: boolean
  subSettings?: SoftwareSubSettings
}

export interface SoftwareSubSettings {
  subId: number
  softwareId: number
  isActive: boolean
  enable: boolean
  success: true
  resultMessages: string[]
}

export interface EnableThirdPartyModel {
  softwareName: string
  request: EnableThirdPartyRequest
}

export interface EnableThirdPartyRequest {
  customerId?: number
  subId: number
  softwareId: number
  enable: boolean
}

export interface ThirdPartyState {
  thirdPartySubscriptions: ThirdPartyData | null
  subscriptionsFetchStatus: number
  thirdPartyErrorMessage: string
  thirdPartyAccessType: ThirdPartyAccessType | null
  accessFetchStatus: number
  thirdPartySave: SoftwareSubSettings | null
  enableThirdPartyStatus: number
}

export const initialState: ThirdPartyState = {
  thirdPartySubscriptions: null,
  subscriptionsFetchStatus: 0,
  thirdPartyErrorMessage: '',
  thirdPartyAccessType: null,
  accessFetchStatus: 0,
  thirdPartySave: null,
  enableThirdPartyStatus: 0,
}

import { authAction } from './Slice'
import { Store } from '@reduxjs/toolkit'
import { User as LoggedInUser } from './Models/user-model'
import { oktaAuth } from './okta'
import ApiService, {
  setAuthorizationToken,
} from '../components/ProfileManagement/Helper/api-service'
import { UserClaims } from '@okta/okta-auth-js'
import {
  GET_IS_GAIN_BROKER,
  GET_IS_GAIN_USER,
} from 'app/components/ProfileManagement/Helper/constants'

export const onUserLoaded = (
  store: Store,
  user: UserClaims,
  accessToken: string,
  idToken: string
) => {
  const convertedUser: LoggedInUser = {
    id_token: idToken || '',
    access_token: accessToken || '',
    profile: {
      auth_time: user.auth_time,
      email: user.email,
      email_verified: user.email_verified,
      name: user.name,
      family_name: user.family_name,
      given_name: user.given_name,
      cds_id: user.cds_id?.toString(),
      preferred_username: user.preferred_username,
      user_type: user.user_type?.toString(),
      locale: user.locale,
      organization: user.organization?.toString(),
      zoneinfo: user.zoneinfo,
    },
  }

  store.dispatch(authAction.storeUser(convertedUser))
  setAuthorizationToken(convertedUser.access_token)
}

export async function loadUserFromStorage(store: Store) {
  try {
    const hasUser = await oktaAuth.isAuthenticated()
    if (!hasUser) {
      return store.dispatch(authAction.storeUserError())
    } else {
      oktaAuth
        .getUser()
        .then(user => {
          const idToken = oktaAuth.getIdToken() || ''
          const accessToken = oktaAuth.getAccessToken() || ''
          onUserLoaded(store, user, accessToken, idToken)
        })
        .catch(e => {
          store.dispatch(authAction.storeUserError())
        })
    }
  } catch (e) {
    store.dispatch(authAction.storeUserError())
  }
}

export function signinRedirect(store: Store) {
  store.dispatch(authAction.loadingUser())
  return oktaAuth.signInWithRedirect()
}

export function signinRedirectCallback(redirectURLPath: string) {
  oktaAuth.setOriginalUri(redirectURLPath)
  oktaAuth.handleLoginRedirect()
}

export function signoutRedirect(store: Store) {
  store.dispatch(authAction.userSignedOut())
  oktaAuth.revokeAccessToken()
  return oktaAuth.signOut()
}

export function signoutRedirectCallback() {
  sessionStorage.removeItem('auth:redirect')
  return oktaAuth.revokeAccessToken()
}

export async function getIsGainUserType(): Promise<boolean> {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const ENDPOINT = `${BASE_URL}${GET_IS_GAIN_USER}`

  const response = await ApiService.get(ENDPOINT)
  return response.data
}
export async function getIsGainBroker(): Promise<boolean> {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const ENDPOINT = `${BASE_URL}${GET_IS_GAIN_BROKER}`
  const response = await ApiService.get(ENDPOINT)
  return response.data
}

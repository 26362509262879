import React from 'react'
import { useEffect } from 'react'
import { Spinner } from '../components/ReusableComponents/spinner'
import { signinRedirect } from './user-service'
import { useStore } from 'react-redux'
import { UserFetchStatus } from './Models/user-fetch-status.enum'

interface LoginProps {
  userStatus?: UserFetchStatus
}

function Login(loginProps: LoginProps) {
  const store = useStore()

  useEffect(() => {
    async function signinRedirectAsync() {
      await signinRedirect(store)
    }
    if (!loginProps || loginProps.userStatus !== UserFetchStatus.Loaded) {
      signinRedirectAsync()
    }
  }, [loginProps, store])

  return <Spinner />
}

export default Login

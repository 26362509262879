import { Country, ProcessingStatus, State } from 'app/shared/models/models'

export interface PersonalInformation {
  contactId: string
  firstName: string
  lastName: string
  middleName: string
  email: string
  jobTitle: string
  dateOfBirth: Date
  countryOfCitizenship: string
  employmentStatus: string
  employerName: string
  phones: phone[]
  governmentId: string
  applicationIds?: string[]
  applicationTypes: ApplicationType[]
  canAccessProfile: boolean
  address?: Address
  types?: number[]
  //If contact update send all accounts
  acountsToSend?: string
}

export interface ApplicationType {
  typeId: string
  type: string
  source: string
}

export interface UpdateContactResponse {
  success: boolean
  message: string
}

export interface phone {
  number: string
  type: string
}

export interface PersonalInfoAccountUpdates {
  status: string
  personalPhoneDisabled: boolean
  personalAddressDisabled: boolean
}

export interface AccountDetailsUpdates {
  accountID: string
  accountLevelUpdates: string
  accountNumber: string
  newEmail: string
  status: string
}

export interface AccountAddressUpdate extends BaseAddress {
  addressId?: string
  applicationId: string
  types: string[]
}

export interface Address extends BaseAddress {
  addressId?: string
  types: string[]
  addressType: string
}

export interface BaseAddress {
  addressName?: string
  country?: string
  line1: string
  line2?: string
  line3?: string
  line4?: string
  city: string
  state?: string
  postalCode: string
  uaeFreeZone?: string
}

export interface UpdateContactAccountRequest {
  //current email of user
  accountId: string
  name?: string
  email?: string
  phoneNumber?: string
  submittedBy: string
  submittedOn: string
  currentNameOfContact: string
  //Only 'Submitted'
  status: string
  //FCM I believe
  applicationType?: string
  companyName?: string
  accountNumber?: string
  maStreetAddress1?: string
  maStreetAddress2?: string
  maCity?: string
  maState?: string
  maZip?: string
  //United States of America
  maCountry?: string
  source: string
  isMailingAddressSameAsPhysicalAddress?: string
  account_Name?: string
  account_MaStreetAddress1?: string
  account_MaStreetAddress2?: string
  account_MaCity?: string
  account_MaState?: string
  account_MaZip?: string
  //United States of America
  account_MaCountry?: string
  account_PaStreetAddress1?: string
  account_PaStreetAddress2?: string
  account_PaCity?: string
  account_PaState?: string
  account_PaZip?: string
  //United States of America
  account_PaCountry?: string
  //Yes for Account Address updates, no for contact level updates
  accountLevelUpdates: string
  brokerEmail: string
}

export interface Account {
  applicationId?: string
  accountId?: string
  name?: string
  companyType?: string
  primaryAccountId?: string
  owner: string
}

export interface AccountAddress {
  accountId: string
  applicationId: string
  addresses: Address[]
}

export interface AddressUpdateDetails {
  address1Changed: boolean
  address1Valid: boolean | null
  updatedAddress1: Address
  address2Changed?: boolean
  address2Valid: boolean | null
  updatedAddress2?: Address
  account?: Account
}

export interface ShowEditAccountAddressPage {
  show: boolean
  selectedAccount?: Account
}

export interface PersonalInfoState {
  personalInfo: PersonalInformation | null
  personalInfoFetchStatus: number
  savePersonalInfoErrorResponse: string | null
  personalInfoSaveStatus: number
  personPostData: UpdateContactAccountRequest | null
  saveAccountAddressErrorResponse: string | null
  accountAddressSaveStatus: number
  accountAddressPostData: UpdateContactAccountRequest | null
  addressesToUpdate: Address[]
  countries: Country[] | null
  countriesFetchStatus: number
  states: State[] | null
  stateFetchStatus: number
  accounts: Account[] | null
  accountsFetchStatus: number
  accountAddresses: AccountAddress[]
  accountToUpdate: Account
  selectedAccount: Account | null
  accountAddressFetchStatus: number
  showEditAccountAddressPage: ShowEditAccountAddressPage
  showEditPersonalnfoPage: boolean
  accountDetailsUpdate: AccountDetailsUpdates[]
  accountDetailsFetch: ProcessingStatus
}

export const initialState: PersonalInfoState = {
  personalInfo: null,
  personalInfoFetchStatus: 0,
  personalInfoSaveStatus: 0,
  personPostData: null,
  savePersonalInfoErrorResponse: null,
  accountAddressSaveStatus: 0,
  accountAddressPostData: null,
  saveAccountAddressErrorResponse: null,
  addressesToUpdate: null,
  countries: null,
  countriesFetchStatus: 0,
  states: null,
  stateFetchStatus: 0,
  accounts: null,
  accountsFetchStatus: 0,
  accountAddresses: [],
  accountToUpdate: null,
  selectedAccount: null,
  accountAddressFetchStatus: 0,
  showEditAccountAddressPage: { show: false, selectedAccount: null },
  showEditPersonalnfoPage: false,
  accountDetailsUpdate: [],
  accountDetailsFetch: ProcessingStatus.RESET,
}

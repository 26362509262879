import React, { useEffect, useRef } from 'react'
import { useAuthSlice } from './Slice/index'
import { Store } from '@reduxjs/toolkit'
import { OktaAuth } from '@okta/okta-auth-js'
import { loadUserFromStorage } from './user-service'
import { useOktaAuth } from './okta'
import { useSelector } from 'react-redux'
import { selectAccessToken } from './Slice/auth-selectors'

interface AuthProviderProps {
  authClient: OktaAuth
  store: Store
  children: React.ReactElement
}

export default function AuthProvider(props: AuthProviderProps) {
  const authClient = useRef<OktaAuth>()
  const store = props.store
  const { actions } = useAuthSlice()
  const { authState } = useOktaAuth()
  const accessToken = useSelector(selectAccessToken)

  useEffect(() => {
    authClient.current = props.authClient

    const onAccessTokenExpired = () => {
      authClient.current?.closeSession()
      sessionStorage.setItem(
        'auth:redirect',
        `${window.location.pathname}${window.location.search}`
      )
      store.dispatch(actions.userTokenExpired())
    }

    // events for user's token
    authClient.current.tokenManager.on('error', onAccessTokenExpired)

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (authClient.current) {
        authClient.current.tokenManager.off('error', onAccessTokenExpired)
      }
    }
  }, [actions, store])

  useEffect(() => {
    const oktaToken = authState?.accessToken?.accessToken
    if (oktaToken && oktaToken !== accessToken) {
      loadUserFromStorage(store)
    }
  }, [authState])

  return React.Children.only(props.children)
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import stoneXLogo from '../../../../images/stonexLogo.svg'
import Button from '@mui/material/Button'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import styles from './appBar.module.scss'
import { useNavigate } from 'react-router'
import { LOGIN, LOGOUT } from 'app/components/ProfileManagement/Helper/constants'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import { selectLoggedInUser } from 'app/authorization/Slice/auth-selectors'
import { useDispatch } from 'react-redux'
import { useContactSupportSlice } from 'app/components/ContactSupport/Slice/contactSupport.slice'
import { isMono } from '@mono/api'
import { IconButton, Menu, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import useMediaQuery from '@mui/material/useMediaQuery'
import ContactSupport from 'app/components/ContactSupport/contactSupport'
import { translate } from 'app/shared/localization/helper/localization-helper'

export default function ButtonAppBar(props: any) {
  const user = useSelector(selectLoggedInUser)
  const dispatch = useDispatch()
  const { actions } = useContactSupportSlice()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const mobileSize = useMediaQuery('(max-width:1000px)')

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const desktopAppBar = (): JSX.Element => {
    return (
      <AppBar position="static" className={styles.appBar} color="inherit">
        <Toolbar>
          <img src={stoneXLogo} alt="Stone X Logo" />
          <p className={styles.pageTitle}>
            {translate('t__AppAndUserSettings', 'Application & User Settings', 'Headers')}
          </p>
          <div className={styles.containerProfile}>
            {user && user.profile.name !== '' ? (
              <Button color="inherit" onClick={() => navigate(LOGOUT)}>
                {user.profile.name} ({translate('t__SignOut', 'Sign-Out', 'Common')})
              </Button>
            ) : (
              <Button color="inherit" onClick={() => navigate(LOGIN)}>
                {translate('t__SignIn', 'Sign-In', 'Common')}
              </Button>
            )}
          </div>
          {user && (
            <HelpOutlineIcon
              onClick={() => dispatch(actions.setShowModalStatus(true))}
              style={{ cursor: 'pointer' }}
            />
          )}
        </Toolbar>
      </AppBar>
    )
  }

  const mobileAppBar = (): JSX.Element => {
    return (
      <AppBar position="static" color="inherit">
        <Toolbar>
          <img src={stoneXLogo} alt="Stone X Logo" />
          <p className={styles.pageTitle}>
            {translate('t__AppAndUserSettings', 'Application & User Settings', 'Headers')}
          </p>
          <div className={styles.rightContent}>
            <IconButton color="inherit" onClick={handleMenu}>
              <MoreVertIcon style={{ cursor: 'pointer' }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>
                {user && user.profile.name !== '' ? (
                  <Button color="inherit" onClick={() => navigate(LOGOUT)}>
                    {user.profile.name} (Sign-Out)
                  </Button>
                ) : (
                  <Button color="inherit" onClick={() => navigate(LOGIN)}>
                    Sign-In
                  </Button>
                )}
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <IconButton
                  color="inherit"
                  onClick={() => dispatch(actions.setShowModalStatus(true))}
                >
                  <HelpOutlineIcon style={{ cursor: 'pointer' }} />
                </IconButton>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    )
  }

  return (
    <>
      {!isMono && (
        <Box sx={{ flexGrow: 1 }}>
          {mobileSize ? mobileAppBar() : desktopAppBar()}
          <ContactSupport />
        </Box>
      )}
    </>
  )
}

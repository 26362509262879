import { Country, State } from 'app/shared/models/models'
import {
  DataProvider,
  Entitlement,
  ExchangePage,
  GridEntitlementKeyMap,
  GridEntitlementsRows,
  GridProvider,
  GridSoftware,
  LicenseType,
  MarketDataCardDetails,
  MarketDataCurrentBilling,
  MarketDataSaveBillingBody,
  PriceSumHelper,
  SaveMarketDataEntitlementsBody,
  UnsubscibeEntitlementsState,
  UserPaymentChoice,
} from '../Model'
import {
  ADDRESS,
  CARD_CVV,
  CARD_EXPIRATION_DATE,
  CARD_TYPE,
  CITY,
  COUNTRY,
  COUNTRY_ID,
  CREDIT_CARD_NUMBER,
  FIRST_NAME,
  LAST_NAME,
  STATE_REGION_PROVINCE,
  STATE_REGION_PROVINCE_ID,
  ZIP_POSTAL_CODE,
} from './constants'
import { UserAccounts } from 'app/components/SoftwareSubscriptions/Model'
import { getEntitledPriceSum, setRowsWithInitialPricingValues } from './pricing-helper'

const setRowsWithBundleData = (rows: GridEntitlementsRows[], bundle: GridEntitlementKeyMap[]) => {
  const initializedRows = rows.map(row => {
    const update = { ...row }
    update.rowData = row.rowData.map(x => {
      const item = { ...x }
      const matchToBundle = bundle.find(b => b?.licenseId === x.licenseId)
      item.bundlePrice = item.bundlePrice ? item.bundlePrice : matchToBundle.bundlePrice
      item.adjustedBundlePrice = matchToBundle?.adjustedBundlePrice
      return item
    })
    return update
  })
  const dataArr = rows.reduce((acc: GridEntitlementKeyMap[], cur) => {
    if (!cur.isBundleRow) {
      cur.rowData.forEach(x => {
        if (cur.gridCheckboxValue === x.licenseId) {
          acc.push(x)
        }
      })
    }
    return acc
  }, [])
  const licenseCheckSatus = dataArr.filter(
    (v, i, a) => a.findIndex(f => f.licenseId === v.licenseId) === i
  )
  if (licenseCheckSatus.length === 1 && dataArr.length === rows.length - 1) {
    const updateRows = initializedRows.map(x => {
      const value = licenseCheckSatus[0].licenseId
      return {
        ...x,
        gridBundleValue: value,
        gridCheckboxValue: value,
        renewable: true,
      }
    })
    return updateRows
  }
  return initializedRows
}

export const getFormattedSoftware = (
  entitlements: Entitlement[],
  providerId: number,
  isProfessional: boolean
) => {
  const ents = [...entitlements]
  const groupedBySoftware = ents.reduce((arr: GridSoftware[], cur: Entitlement, i) => {
    const matchedSoftware = arr.findIndex(x => x.id === cur.software.id)
    const { software, ...curEl } = cur
    if (matchedSoftware === -1) {
      const newItem = {
        ...software,
        rawEntitlements: [curEl],
        licenseCols: [],
      }
      arr.push(newItem)
    } else {
      arr[matchedSoftware].rawEntitlements.push(curEl)
    }
    return arr
  }, [])

  const formatted = groupedBySoftware.map(x => {
    const { rawEntitlements, ...softData } = x
    const result = {
      ...softData,
      gridEntitlements: getFormattedEntitlements(
        rawEntitlements,
        providerId,
        isProfessional,
        softData
      ),
      licenseCols: rawEntitlements
        .map(re => re.license.id)
        .filter((v, i, a) => a.findIndex(f => f === v) === i),
    }
    return result
  })
  return formatted
}

export const getFormattedEntitlements = (
  entitlements: Entitlement[],
  dataProviderId: number,
  isProfessional: boolean,
  softwareInfo: GridSoftware | null = null
) => {
  const ents = [...entitlements]
  const bundleCheck = ents.filter(
    (v, i, a) => a.findIndex(f => f.license.hasBundle && f.license.id === v.license.id) === i
  )
  const formatted: GridEntitlementsRows[] = ents.reduce((arr: GridEntitlementsRows[], cur, i) => {
    const matchedExchange = arr.findIndex(x => x.exchange.id === cur.exchange.id)
    const keyMapData: GridEntitlementKeyMap = {
      licenseId: cur.license.id,
      providerId: dataProviderId,
      exchangeId: cur.exchange.id,
      softwareId: softwareInfo ? softwareInfo.id : null,
      softwareAppTitle: softwareInfo ? softwareInfo.appTitle : null,
      licenseName: cur.license.name,
      licenseDescription: cur.license.description,
      exchangeName: cur.exchange.oecName ? cur.exchange.oecName : cur.exchange.name,
      exchangeDesc: cur.exchange.description,
      price: cur.price,
      adjustedPrice: !cur.entitled ? cur.price : 0,
      bundlePrice: cur.license.bundlePrice ? cur.license.bundlePrice : null,
      adjustedBundlePrice: cur.license.bundlePrice ? cur.license.bundlePrice : null,
      entitled: cur.entitled,
      renewable: cur.renewable,
      shouldCharge: cur.shouldCharge,
    }
    if (matchedExchange === -1) {
      const newItem = {
        rowId: i,
        providerId: dataProviderId,
        exchangeId: cur.exchange.id,
        softwareId: softwareInfo ? softwareInfo.id : null,
        isProfessional: isProfessional,
        gridCheckboxValue: cur.entitled && cur.renewable ? cur.license.id : LicenseType.nonSelected,
        gridBundleValue: LicenseType.nonSelected,
        isBundleRow: false,
        renewable: cur.entitled && cur.renewable,
        exchange: cur.exchange,
        exchangeDesc: cur.exchange.description,
        exchangeName: cur.exchange.oecName ? cur.exchange.oecName : cur.exchange.name,
        rowData: [
          {
            ...keyMapData,
          },
        ],
      }
      arr.push(newItem)
    } else {
      arr[matchedExchange].gridCheckboxValue =
        cur.entitled && cur.renewable ? cur.license.id : arr[matchedExchange].gridCheckboxValue
      arr[matchedExchange].renewable =
        arr[matchedExchange].renewable || (cur.entitled && cur.renewable)
      arr[matchedExchange].rowData.push(keyMapData)
    }
    return arr
  }, [])

  if (bundleCheck.length) {
    const entitledPriceSum = getEntitledPriceSum(formatted)
    const bundleRowData = bundleCheck.reduce((a: GridEntitlementKeyMap[], c, i) => {
      const isEntitled = formatted.reduce((b, v) => {
        const item = v.rowData.find(x => x.licenseId === c.license.id)
        return b && item.entitled
      }, true)
      const isRenewable = formatted.reduce((b, v) => {
        const item = v.renewable && c.renewable
        return b && item
      }, true)
      const adjLicensePriceSum = formatted.reduce((p, c) => {
        const sumResult = p + c.rowData[i].adjustedPrice
        return sumResult
      }, 0)
      const adjBundlePriceProVsNonPro =
        c.license.bundlePrice !== null ? c.license.bundlePrice : adjLicensePriceSum
      const defualtBundlePrice = formatted.reduce((p, c) => {
        const sumResult = p + c.rowData[i].price
        return sumResult
      }, 0)
      const defaultbundlePriceProVsNonPro =
        c.license.bundlePrice !== null ? c.license.bundlePrice : defualtBundlePrice
      const bundleInfo: GridEntitlementKeyMap = {
        providerId: dataProviderId,
        exchangeId: -1,
        licenseId: c.license.id,
        softwareId: softwareInfo ? softwareInfo.id : null,
        softwareAppTitle: softwareInfo ? softwareInfo.appTitle : null,
        price: adjLicensePriceSum,
        adjustedPrice: entitledPriceSum < adjBundlePriceProVsNonPro ? adjBundlePriceProVsNonPro : 0,
        entitled: isEntitled,
        renewable: isRenewable,
        shouldCharge: false,
        bundlePrice: defaultbundlePriceProVsNonPro,
        adjustedBundlePrice:
          entitledPriceSum <= adjBundlePriceProVsNonPro ? adjBundlePriceProVsNonPro : 0,
      }
      a.push(bundleInfo)
      return a
    }, [])

    const bundleRow: GridEntitlementsRows = {
      rowId: formatted.length,
      providerId: dataProviderId,
      renewable: false,
      gridCheckboxValue: LicenseType.nonSelected,
      gridBundleValue: LicenseType.nonSelected,
      isProfessional: isProfessional,
      isBundleRow: true,
      exchangeId: -1,
      exchangeDesc: '',
      exchangeName: 'Bundle',
      rowData: bundleRowData,
      softwareId: null,
    }
    const pushBundle = [...formatted, bundleRow]
    const constructedRowsWithBundle = setRowsWithBundleData(pushBundle, bundleRowData)
    const rowsWithAdjustedPricesSet = setRowsWithInitialPricingValues(constructedRowsWithBundle)
    return rowsWithAdjustedPricesSet
  }
  return formatted
}

export const getFormattedDataProviders = (responseData: ExchangePage) => {
  const resData = { ...responseData }
  const formattedProviders = resData.dataProviders?.map((value, i) => {
    const hasSoftWare = value.entitlements.some((x: Entitlement) => x.software)
    const newProvider: GridProvider = {
      gridId: i,
      providerId: value.id,
      providerName: value.name,
      hasEntitlements: value.entitlements.length !== 0,
      providerDescription: value.description,
      gridEntitlements: !hasSoftWare
        ? getFormattedEntitlements(value.entitlements, value.id, resData.professional)
        : [],
      licenseCols: value.entitlements
        .map(x => x.license.id)
        .filter((v, i, a) => a.findIndex(f => f === v) === i),
      hasSoftware: hasSoftWare,
      softwareInfo: hasSoftWare
        ? getFormattedSoftware(value.entitlements, value.id, resData.professional)
        : [],
    }
    return newProvider
  })
  return formattedProviders
}

export const getGridKeyMappings = (providers: GridProvider[]) => {
  const result = providers.reduce((a: GridEntitlementKeyMap[], c, i) => {
    if (c.hasSoftware) {
      const softwareEntitlements = c.softwareInfo.flatMap(x => x.gridEntitlements)
      const rowData = softwareEntitlements.flatMap(x => x.rowData)
      a.push(...rowData)
    } else {
      const rowData = c.gridEntitlements.flatMap(x => x.rowData)
      a.push(...rowData)
    }
    return a
  }, [])
  return result
}

export const getUnsubscibeState = (keyMaps: GridEntitlementKeyMap[]) => {
  const hasEntitled = keyMaps.reduce((b, c) => {
    const value = b || c.renewable
    return value
  }, false)

  if (hasEntitled) return UnsubscibeEntitlementsState.Avalible
  return UnsubscibeEntitlementsState.Unavalible
}

export const getNewUserUpdates = (
  userUpdates: GridEntitlementKeyMap[],
  newKeyMaps: GridEntitlementKeyMap[]
): GridEntitlementKeyMap[] => {
  const result = userUpdates.reduce((a, c) => {
    const itemToReplace = newKeyMaps.find(
      x =>
        x.providerId === c.providerId &&
        x.exchangeId === c.exchangeId &&
        x.licenseId === c.licenseId &&
        x.softwareId === c.softwareId
    )
    if (itemToReplace) {
      a.push(itemToReplace)
    } else {
      a.push(c)
    }
    return a
  }, [])
  return result
}

export const determineDataMatch = (
  initialData: GridEntitlementKeyMap[],
  updateData: GridEntitlementKeyMap[]
): boolean => {
  const result = initialData.reduce((a, c) => {
    const findMatch = updateData.find(
      x =>
        x.providerId === c.providerId &&
        x.exchangeId === c.exchangeId &&
        x.licenseId === c.licenseId &&
        x.softwareId === c.softwareId &&
        x.entitled === c.entitled
    )
    if (findMatch) {
      const renewableSame = findMatch.renewable === c.renewable
      return a && renewableSame
    }
    return a
  }, true)
  return result
}

export const currencyFormatter = (
  currencyCode = 'USD',
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
): Intl.NumberFormat => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits,
    maximumFractionDigits,
  })
}

const priceSumming = (arr: GridEntitlementKeyMap[], useDefaultPrice: boolean) => {
  const result = arr.reduce((a: PriceSumHelper[], c) => {
    const price = useDefaultPrice ? c.price : c.adjustedPrice
    const rowBundlePrice = useDefaultPrice ? c.bundlePrice : c.adjustedBundlePrice
    const matchIndex = a.findIndex(x => x.licenseId === c.licenseId)
    if (matchIndex !== -1 && a[matchIndex]?.bundlePrice) {
      if (a[matchIndex].summedPrice <= c.bundlePrice) {
        a[matchIndex].summedPrice = a[matchIndex].summedPrice + price
      }
    } else {
      a.push({
        bundlePrice: rowBundlePrice,
        licenseId: c.licenseId,
        summedPrice: price,
      })
    }
    return a
  }, [])
  return result
}

export const calcCostInformation = (
  keyMapArr: GridEntitlementKeyMap[],
  costData: PriceSumHelper,
  paymentSelection: UserPaymentChoice
) => {
  const costObj = { ...costData }
  const useCCFee = paymentSelection === UserPaymentChoice.CreditCard ? costData.ccFee : 0
  const renewable = keyMapArr.filter(x => x.renewable && x.exchangeId !== -1)
  const todaysChanges = keyMapArr.filter(x => !x.entitled && x.renewable && x.exchangeId !== -1)
  const isBundleChecked = keyMapArr.find(x => x.exchangeId === -1 && x.renewable)
  const todaysGroupedSum = priceSumming(todaysChanges, false)
  const monthlyGroupedSum = priceSumming(renewable, true)
  const todaysCostSum = todaysGroupedSum.reduce((p, c) => {
    if (isBundleChecked && isBundleChecked.licenseId === c.licenseId) {
      return isBundleChecked.adjustedBundlePrice
    }
    const cost = c.summedPrice + p
    return cost
  }, 0)

  const monthlyCostSum = monthlyGroupedSum.reduce((p, c) => {
    if (isBundleChecked && isBundleChecked.licenseId === c.licenseId) {
      return isBundleChecked.bundlePrice
    }
    const cost = c.summedPrice + p
    return cost
  }, 0)

  costObj.todayDataFee = todaysCostSum
  costObj.todayAdminFee = todaysCostSum * costData.adminFee
  costObj.todayConvienceFee = todaysCostSum * useCCFee
  costObj.todayCharge = todaysCostSum * costData.adminFee + todaysCostSum * useCCFee + todaysCostSum

  costObj.monthlyAdminFee = monthlyCostSum * costData.adminFee
  costObj.monthlyCost =
    monthlyCostSum * costData.adminFee + monthlyCostSum * useCCFee + monthlyCostSum

  return costObj
}
export const mergeUnsubscirbedProviders = (
  initialData: ExchangePage,
  providers: DataProvider[]
) => {
  const { dataProviders, ...data } = initialData
  const updatedExchangePage = {
    ...data,
    dataProviders: [...providers],
  }
  return updatedExchangePage
}
export const mapCurrentBillingToDetails = (
  data: MarketDataCurrentBilling | null,
  country: Country | null,
  state: State | null
) => {
  const resData: MarketDataCurrentBilling = { ...data }
  const result: MarketDataCardDetails = {
    id: resData.id ? resData.id : -1,
    [FIRST_NAME]: resData.firstName ? resData.firstName : '',
    [LAST_NAME]: resData.lastName ? resData.lastName : '',
    [ADDRESS]: resData.address ? resData.address : '',
    email: '',
    [CITY]: resData.city ? resData.city : '',
    [STATE_REGION_PROVINCE_ID]: state?.id ? state.id : -1,
    [STATE_REGION_PROVINCE]: state?.name ? state.name : '',
    [ZIP_POSTAL_CODE]: resData.zip ? resData.zip : '',
    [COUNTRY_ID]: country?.id ? country.id : 255,
    [COUNTRY]: country?.name ? country.name : 'United States of America',
    [CREDIT_CARD_NUMBER]: resData.number ? resData.number : '',
    [CARD_EXPIRATION_DATE]: resData.expDate ? resData.expDate : '',
    [CARD_CVV]: resData.code ? resData.code : '',
    [CARD_TYPE]: resData.cardType ? resData.cardType : '',
    hasProfile: resData.hasProfile,
    creditCardUpdated: false,
  }
  return result
}
export const mapUnsubscribeEntitlements = (
  initialMarketData: ExchangePage,
  PaymentMethod: UserPaymentChoice,
  FutureAccount: UserAccounts
) => {
  const nonRenewableEntitlements = initialMarketData.dataProviders.map(prov => {
    const result = { ...prov }
    result.entitlements = result.entitlements.map(ent => {
      const update = { ...ent }
      update.renewable = false
      return update
    })
    return result
  })

  const postBody: SaveMarketDataEntitlementsBody = {
    customerId: null,
    isManaging: false,
    acceptedIceAgreement: true,
    acceptedCmeNonProAgreement: true,
    paymentType: PaymentMethod,
    sendCCReceipt: false,
    accountToPayFrom:
      PaymentMethod === UserPaymentChoice.FuturesAccount ? FutureAccount.accountNumber : '',
    dataProviders: [...nonRenewableEntitlements],
  }
  return postBody
}
export const mapUpdatesToMarketDataObj = (
  initialMarketData: ExchangePage,
  newUserSelections: GridEntitlementKeyMap[],
  PaymentMethod: UserPaymentChoice,
  FutureAccount: UserAccounts,
  sendCCEmailReceipt: boolean
) => {
  const newDataProviders = initialMarketData.dataProviders.map(prov => {
    const result = { ...prov }
    result.entitlements = result.entitlements.map(ent => {
      const entResult = { ...ent }
      const match = newUserSelections.find(x => {
        let result = false
        result = x.licenseId === ent.license.id && x.exchangeId === ent.exchange.id
        if (x.softwareId !== null) {
          result = result && x.softwareId === ent.software?.id
        }
        return result
      })
      if (match) {
        entResult.renewable = match.renewable
      }
      return entResult
    })
    return result
  })

  const postBody: SaveMarketDataEntitlementsBody = {
    customerId: null,
    isManaging: false,
    acceptedIceAgreement: true,
    acceptedCmeNonProAgreement: true,
    paymentType: PaymentMethod,
    sendCCReceipt: sendCCEmailReceipt,
    accountToPayFrom:
      PaymentMethod === UserPaymentChoice.FuturesAccount ? FutureAccount.accountNumber : '',
    dataProviders: [...newDataProviders],
  }

  return postBody
}

export const buildSaveBillingBody = (
  cardDetails: MarketDataCardDetails,
  updateCardDetailsChecked: boolean,
  arbAgreeChecked: boolean,
  onlineAgreeChecked: boolean
) => {
  const billingBody: MarketDataSaveBillingBody = {
    firstName: cardDetails[FIRST_NAME],
    lastName: cardDetails[LAST_NAME],
    address: cardDetails[ADDRESS],
    city: cardDetails[CITY],
    stateId: cardDetails[STATE_REGION_PROVINCE_ID],
    zip: cardDetails[ZIP_POSTAL_CODE],
    countryId: cardDetails[COUNTRY_ID],
    agreeArb: arbAgreeChecked,
    agreeTerms: onlineAgreeChecked,
    amount: 0,
    creditCardUpdated: updateCardDetailsChecked,
    customerId: null,
  }
  if (updateCardDetailsChecked) {
    const billingAndCardInfo = {
      ...billingBody,
      number: cardDetails[CREDIT_CARD_NUMBER],
      expDate: cardDetails[CARD_EXPIRATION_DATE],
      code: cardDetails[CARD_CVV],
    }
    return billingAndCardInfo
  }
  return billingBody
}

import { StrictMode } from 'react'
import { Provider } from 'react-redux'
// Initialize languages
import './i18n'
import React from 'react'
import { Store } from '@reduxjs/toolkit'
import { HelmetProvider } from 'react-helmet-async'

interface InitializeWidgetOrAppProps {
  Component: React.ComponentType
  store: Store
}

export default function InitializeWidgetOrApp({
  Component,
  store: profileManageStore,
}: InitializeWidgetOrAppProps) {
  return (
    <Provider store={profileManageStore}>
      <HelmetProvider>
        <StrictMode>
          <Component />
        </StrictMode>
      </HelmetProvider>
    </Provider>
  )
}

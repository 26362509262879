/* eslint-disable @typescript-eslint/no-explicit-any */
import ApiService from 'app/components/ProfileManagement/Helper/api-service'
import { NewSubscriptionDataObj, SubscriptionsData, UserAccounts } from '../Model'

import {
  ACCOUNTS_API,
  ACTIVE_SUBSCRIPTION_API,
  UPDATE_SUBSCRIPTION_API,
} from 'app/components/ProfileManagement/Helper/constants'

export const activeSubscriptionServiceAPI = async (
  customerId?: number
): Promise<SubscriptionsData[]> => {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const ENDPOINT = `${BASE_URL}${ACTIVE_SUBSCRIPTION_API}`
  const url = customerId ? `${ENDPOINT}?customerId=${customerId}` : ENDPOINT
  const data: any = await ApiService.get(url)

  const activeSubscriptionData: SubscriptionsData[] = data?.data

  return activeSubscriptionData
}

export const userAccountServiceAPI = async (customerId?: number): Promise<UserAccounts> => {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const ENDPOINT = `${BASE_URL}${ACCOUNTS_API}`
  const url = customerId ? `${ENDPOINT}?customerId=${customerId}` : ENDPOINT
  const accountsDataArr: UserAccounts = await ApiService.get(url)

  return accountsDataArr
}

export const postSubscriptionUpdateAPI = async (
  subscriptionDetails: NewSubscriptionDataObj
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const ENDPOINT = `${BASE_URL}${UPDATE_SUBSCRIPTION_API}`

  const response = await ApiService.post(ENDPOINT, subscriptionDetails).catch(function (error) {
    throw error
  })

  return response
}

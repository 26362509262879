import { PayloadAction } from '@reduxjs/toolkit'
import { User } from '../Models/user-model'
import { initialState } from '../Models/auth-state-model'
import { UserFetchStatus } from '../Models/user-fetch-status.enum'
import { authorizationFromSaga } from './authorization.saga'
import { createSlice } from 'store/@reduxjs/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'
import { ILinkDetails } from 'app/components/NavigationPanel/Interfaces/navigationPanelInterface'

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    storeUser(state, action: PayloadAction<User>) {
      state.userFetchStatus = UserFetchStatus.Loaded
      state.user = action.payload
    },
    getIsGainUser(state) {
      state.userIsGainCustomerFetchStatus = UserFetchStatus.Loading
    },
    storeIsGainUser(state, action: PayloadAction<boolean>) {
      state.userIsGainCustomer = action.payload
      state.userIsGainCustomerFetchStatus = UserFetchStatus.Loaded
    },
    loadingUser(state, action: PayloadAction) {
      state.userFetchStatus = UserFetchStatus.Loading
    },
    userSignedOut(state, action: PayloadAction) {
      state.user = null
      state.userFetchStatus = UserFetchStatus.Removed
    },
    userTokenExpired() {},
    userExpired(state) {
      state.user = null
      state.userFetchStatus = UserFetchStatus.Expired
    },
    storeUserError(state, action: PayloadAction) {
      state.user = null
      state.userFetchStatus = UserFetchStatus.Error
    },
    isEmployeeBroker(state, action: PayloadAction<boolean>) {
      state.userIsBroker = action.payload
    },
    setNavigationBasedOnUser(state, action: PayloadAction<ILinkDetails[]>) {
      state.userNavItems = action.payload
    },
  },
})

export const { actions: authAction, reducer } = slice

export const useAuthSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  useInjectSaga({ key: slice.name, saga: authorizationFromSaga })
  return { actions: slice.actions }
}

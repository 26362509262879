import React from 'react'
import { useEffect } from 'react'
import { Spinner } from '../components/ReusableComponents/spinner'
import { signoutRedirect } from './user-service'
import { useStore } from 'react-redux'

//create this component if any extra handling needs to be done for logout before redirecting it
//Login and logiut componenet can be removed in future if no extra handling is needed
function Logout() {
  const store = useStore()
  useEffect(() => {
    async function signOutRedirectAsync() {
      await signoutRedirect(store)
    }
    signOutRedirectAsync()
  }, [store])
  return <Spinner />
}

export default Logout

/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import { personalInfoActions as actions } from './personalInfo.slice'
import { GetStatesAPI } from 'app/shared/services'
import {
  Account,
  AccountDetailsUpdates,
  PersonalInformation,
  UpdateContactAccountRequest,
} from '../Model'
import {
  GetAccountAddressesAPI,
  GetAccountDetailsAPI,
  GetPersonalInfoAPI,
  getAccountUpdateStatus,
  postCountriesAPI,
  postSavePersonalInfoAPI,
} from '../Services/personalInfo.service'
import { Country, states } from 'app/shared/models/models'
import {
  doesContainLetters,
  numberValidation,
} from 'app/components/ProfileManagement/Helper/globalHelper'

export function* GetStates(country: PayloadAction<number>) {
  try {
    const data: any = yield call(GetStatesAPI, country.payload)
    if (data !== null) {
      yield put(actions.statesLoaded(data))
    } else {
      yield put(actions.statesError(null))
    }
  } catch (err) {
    yield put(actions.statesLoaded(states))
  }
}

export function* GetCountries(countries: PayloadAction<unknown>) {
  try {
    const data: Country[] = yield call(postCountriesAPI, countries.payload)
    if (data !== null) {
      yield put(actions.countriesLoaded(data))
    } else {
      yield put(actions.countriesError(null))
    }
  } catch (err) {
    yield put(actions.countriesError(null))
  }
}

export function* GetPersonalInfo() {
  try {
    const data: PersonalInformation = yield call(GetPersonalInfoAPI)
    if (data !== null) {
      const convertToValidAddressData = (personalData: PersonalInformation) => {
        if (personalData?.phones?.length > 0) {
          personalData?.phones?.forEach(phone => {
            if (!numberValidation(phone.number) && phone.number !== '') {
              phone.number = ''
            }
          })
        }

        if (personalData?.address?.city && personalData?.address?.city !== '') {
          if (!doesContainLetters(personalData?.address?.city)) {
            personalData.address.city = ''
          }
        }

        if (personalData?.address?.postalCode && personalData?.address?.postalCode !== '') {
          if (!numberValidation(personalData.address.postalCode)) {
            personalData.address.postalCode = ''
          }
        }

        return personalData
      }

      const convertedData = convertToValidAddressData(data)

      yield put(actions.getPersonalInfoLoaded(convertedData))
      if (convertedData?.applicationIds?.length) {
        yield put(actions.getAccountDetails(convertedData.applicationIds))
      }
    } else {
      yield put(actions.getPersonalInfoError(null))
    }
  } catch (err) {
    yield put(actions.getPersonalInfoError(null))
  }
}
export function* GetAccountUpdateStatus() {
  try {
    const status: AccountDetailsUpdates[] = yield call(getAccountUpdateStatus)
    if (status.length) {
      status.reverse()
      yield put(actions.setAccountDetailsUpdate(status))
    } else {
      yield put(actions.setAccountDetailsUpdate(status))
    }
  } catch (err) {
    yield put(actions.errorAccountDetailsUpdate())
  }
}

export function* GetAccountDetails(applicationIds: PayloadAction<string[]>) {
  try {
    const data: any = yield call(GetAccountDetailsAPI, applicationIds.payload)
    if (data !== null) {
      yield put(actions.getAccountDetailsLoaded(data))
    } else {
      yield put(actions.getAccountsError(null))
    }
  } catch (err) {
    yield put(actions.getAccountsError(null))
  }
}

export function* GetAccountAddresses(account: PayloadAction<Account>) {
  try {
    const data: any = yield call(GetAccountAddressesAPI, account.payload)
    if (data !== null) {
      yield put(actions.getAccountAddressesLoaded(data))
    } else {
      yield put(actions.getAccountAddressesError())
    }
  } catch (err) {
    yield put(actions.getAccountAddressesError())
  }
}

export function* SavePersonalInfo(action: PayloadAction<UpdateContactAccountRequest>) {
  try {
    const postResponse: any = yield call(postSavePersonalInfoAPI, action.payload)
    if (postResponse.success === true) {
      const statusObject: AccountDetailsUpdates = {
        accountID: `${action.payload.accountId}`,
        accountLevelUpdates: 'no',
        accountNumber: '',
        newEmail: '',
        status: 'pending compliance approval',
      }
      yield put(actions.putAccountDetailsUpdate(statusObject))
      yield put(actions.personalInfoProcessingComplete(true))
    } else {
      yield put(actions.personalInfoProcessingError(postResponse.message))
    }
  } catch (err) {
    if (err.toString().indexOf('403', 0) > 0) {
      yield put(
        actions.personalInfoProcessingError(
          'You do not have permissions to edit the personal details.'
        )
      )
    } else {
      yield put(actions.personalInfoProcessingError('An error occurred saving personal details.'))
    }
  }
}

export function* SaveAccountAddress(action: PayloadAction<UpdateContactAccountRequest>) {
  try {
    const postResponse: any = yield call(postSavePersonalInfoAPI, action.payload)

    if (postResponse.success === true) {
      const statusObject: AccountDetailsUpdates = {
        accountID: `${action.payload.accountId}`,
        accountLevelUpdates: 'yes',
        accountNumber: `${action.payload.accountNumber}`,
        newEmail: '',
        status: 'pending compliance approval',
      }
      yield put(actions.putAccountDetailsUpdate(statusObject))
      yield put(actions.updateAccountAddressessProcessingComplete(true))
    } else {
      yield put(
        actions.AccountAddressesProcessingError('An error occurred saving account address.')
      )
    }
  } catch (err) {
    if (err.toString().indexOf('403', 0) > 0) {
      yield put(
        actions.AccountAddressesProcessingError(
          'You do not have permissions to edit the account addresses.'
        )
      )
    } else {
      yield put(
        actions.AccountAddressesProcessingError('An error occurred saving the account address.')
      )
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* personalInfoSaga() {
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.getPersonalDetailInfo, GetPersonalInfo)
  yield takeLatest(actions.getAccountDetails.type, GetAccountDetails)
  yield takeLeading(actions.getAccountDetailsUpdate, GetAccountUpdateStatus)
  yield takeLatest(actions.savePersonalInfo.type, SavePersonalInfo)
  yield takeLatest(actions.saveAccountAddresses.type, SaveAccountAddress)
  yield takeLatest(actions.getStates.type, GetStates)
  yield takeLatest(actions.getCountries.type, GetCountries)
  yield takeLatest(actions.getAccountAddresses.type, GetAccountAddresses)
}

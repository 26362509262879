import { RouteQueryParams } from './monoMessaging.model'

export interface MonoMessagingIntegrationState {
  queryParams: RouteQueryParams
  monoWidgetId?: string
  monoAppId: string
}

export const initialState: MonoMessagingIntegrationState = {
  queryParams: {},
  monoWidgetId: '',
  monoAppId: '',
}

import { RootState } from 'app/root-state'
import { initialState } from '../Model'
import { createSelector } from '@reduxjs/toolkit'

const selectDomain = (state: RootState) => state.customerOfBroker || initialState

export const customerOfBrokerListStatus = createSelector(
  [selectDomain],
  data => data.customerListStatus
)
export const customerOfBrokerList = createSelector([selectDomain], data => data.customerList)

export const customerOfBrokerSelected = createSelector([selectDomain], data => data.selctedCustomer)

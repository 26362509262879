/**
 * index.tsx
 *
 * This is the entry file for the application, only setup
 * code.
 */

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { LicenseManager } from 'ag-grid-enterprise'
import App from './app/index'
import './index.css'
import { configureAppStore } from './store/root-store'
import { BrowserRouter } from 'react-router-dom'
import InitializeWidgetOrApp from 'app/initialization/widget-app-setup'
LicenseManager.setLicenseKey(
  'CompanyName=Stonex Group Inc.,LicensedGroup=technology-solutions-dev,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=10,LicensedProductionInstancesCount=0,AssetReference=AG-025102,ExpiryDate=17_March_2023_[v2]_MTY3OTAxMTIwMDAwMA==fb9dea8525e7483bbf9e20f4a848329b'
)

const store = configureAppStore()
const MOUNT_NODE = document.getElementById('root') as HTMLElement
const root = createRoot(MOUNT_NODE)

root.render(
  <BrowserRouter>
    <InitializeWidgetOrApp Component={App} store={store} />
  </BrowserRouter>
)

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./app/initialization/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  })
}

import React from 'react'
import styles from './customerCard.module.scss'
import { customerOfBrokerSelected } from '../Slice/customerSearch.selector'
import { useSelector } from 'react-redux'

const CustomerCard: React.FC = () => {
  const customerSelected = useSelector(customerOfBrokerSelected)

  return (
    customerSelected && (
      <div className={styles.customerCard}>
        <div className={styles.customerInfo}>{customerSelected.accountName}</div>
        <div className={styles.customerInfo}>{customerSelected.customerEmail}</div>
        {customerSelected?.accountNumber && (
          <div className={styles.customerInfo}>{customerSelected.accountNumber}</div>
        )}
        {customerSelected?.demo && (
          <div className={styles.customerInfo}>{customerSelected.demo}</div>
        )}
      </div>
    )
  )
}

export default CustomerCard

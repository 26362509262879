/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit'

import { InjectedReducersType } from './injector-typings'
import { reducer as authReducer } from '../app/authorization/Slice'
import { reducer as monoMessagingIntegrationReducer } from 'mono-widgets/Messaging/monoMessaging.slice'

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  return combineReducers({
    auth: authReducer,
    monoMessagingIntegration: monoMessagingIntegrationReducer,
    ...injectedReducers,
  })
}

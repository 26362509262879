import { call, put, takeLatest } from 'redux-saga/effects'
import { ContactSupportActions as actions } from './contactSupport.slice'
import { OfficeData, TicketData } from '../Model/contactSupport.model'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  SendContactSupportEmailAPI,
  getOfficeCodeForSelectedAccount,
} from '../Services/contactSupportService'

export function* sendSupportTicket(action: PayloadAction<TicketData>) {
  const ticketData = action.payload
  try {
    const officeCode: OfficeData[] = yield call(
      getOfficeCodeForSelectedAccount,
      ticketData.accountNumber
    )
    if (officeCode?.length) {
      ticketData.officeCode = officeCode[0].Office
    }
    const response = yield call(SendContactSupportEmailAPI, ticketData)
    if (response.status === 200) {
      yield put(actions.supportTicketComplete())
    } else {
      yield put(actions.supportTicketError())
    }
  } catch (err) {
    yield put(actions.supportTicketError())
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* contactSupportSaga() {
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.sendSupportTicket.type, sendSupportTicket)
}

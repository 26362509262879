export enum ProcessingStatus {
  RESET = 0,
  PENDING = 1,
  COMPLETED = 2,
  ERROR = 3,
}

export interface SimpleIdName {
  id: number
  name: string
}

export interface Country {
  id: number
  name: string
  isO3ALPHA: string
  isO2ALPHA: string
}

export interface State {
  id?: number
  name: string
  abbreviatedName: string
}

export const states: State[] = [
  {
    name: 'Alabama',
    abbreviatedName: 'AL',
  },
  {
    name: 'Alaska',
    abbreviatedName: 'AK',
  },
  {
    name: 'Arizona',
    abbreviatedName: 'AZ',
  },
  {
    name: 'Arkansas',
    abbreviatedName: 'AR',
  },
  {
    name: 'California',
    abbreviatedName: 'CA',
  },
  {
    name: 'Colorado',
    abbreviatedName: 'CO',
  },
  {
    name: 'Connecticut',
    abbreviatedName: 'CT',
  },
  {
    name: 'Delaware',
    abbreviatedName: 'DE',
  },
  {
    name: 'Florida',
    abbreviatedName: 'FL',
  },
  {
    name: 'Georgia',
    abbreviatedName: 'GA',
  },
  {
    name: 'Hawaii',
    abbreviatedName: 'HI',
  },
  {
    name: 'Idaho',
    abbreviatedName: 'ID',
  },
  {
    name: 'Illinois',
    abbreviatedName: 'IL',
  },
  {
    name: 'Indiana',
    abbreviatedName: 'IN',
  },
  {
    name: 'Iowa',
    abbreviatedName: 'IA',
  },
  {
    name: 'Kansas',
    abbreviatedName: 'KS',
  },
  {
    name: 'Kentucky',
    abbreviatedName: 'KY',
  },
  {
    name: 'Louisiana',
    abbreviatedName: 'LA',
  },
  {
    name: 'Maine',
    abbreviatedName: 'ME',
  },
  {
    name: 'Maryland',
    abbreviatedName: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviatedName: 'MA',
  },
  {
    name: 'Michigan',
    abbreviatedName: 'MI',
  },
  {
    name: 'Minnesota',
    abbreviatedName: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviatedName: 'MS',
  },
  {
    name: 'Missouri',
    abbreviatedName: 'MO',
  },
  {
    name: 'Montana',
    abbreviatedName: 'MT',
  },
  {
    name: 'Nebraska',
    abbreviatedName: 'NE',
  },
  {
    name: 'Nevada',
    abbreviatedName: 'NV',
  },
  {
    name: 'New Hampshire',
    abbreviatedName: 'NH',
  },
  {
    name: 'New Jersey',
    abbreviatedName: 'NJ',
  },
  {
    name: 'New Mexico',
    abbreviatedName: 'NM',
  },
  {
    name: 'New York',
    abbreviatedName: 'NY',
  },
  {
    name: 'North Carolina',
    abbreviatedName: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviatedName: 'ND',
  },
  {
    name: 'Ohio',
    abbreviatedName: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviatedName: 'OK',
  },
  {
    name: 'Oregon',
    abbreviatedName: 'OR',
  },
  {
    name: 'Pennsylvania',
    abbreviatedName: 'PA',
  },
  {
    name: 'Rhode Island',
    abbreviatedName: 'RI',
  },
  {
    name: 'South Carolina',
    abbreviatedName: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviatedName: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviatedName: 'TN',
  },
  {
    name: 'Texas',
    abbreviatedName: 'TX',
  },
  {
    name: 'Utah',
    abbreviatedName: 'UT',
  },
  {
    name: 'Vermont',
    abbreviatedName: 'VT',
  },
  {
    name: 'Virginia',
    abbreviatedName: 'VA',
  },
  {
    name: 'Washington',
    abbreviatedName: 'WA',
  },
  {
    name: 'West Virginia',
    abbreviatedName: 'WV',
  },
  {
    name: 'Wisconsin',
    abbreviatedName: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviatedName: 'WY',
  },
]

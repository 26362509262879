import React from 'react'
import styles from './localization-selector.module.scss'
import { useTranslation } from 'react-i18next'
import { selectAvailableLanguages, selectSelectedLanguage } from '../slice/localization.selectors'
import { useDispatch, useSelector } from 'react-redux'
import { useLocalizationSlice } from '../slice/localization.slice'
import { getFriendlyName, getLocizeCode } from '../helper/localization-helper'
import { MenuItem, Select } from '@mui/material'

const LanguageSelector: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const { actions } = useLocalizationSlice()

  const { i18n } = useTranslation()
  const availableLanguages = useSelector(selectAvailableLanguages)
  const selectedLanguage = useSelector(selectSelectedLanguage)
  const changeLanguage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const code = getLocizeCode(e.target.value)
    dispatch(actions.setSelectedLanguage(code))
    i18n.changeLanguage(code)
  }
  return (
    <>
      {availableLanguages && process.env.REACT_APP_ENABLE_LOCALIZATION === 'true' && (
        <div className={styles['dropdown-width']}>
          <Select
            sx={{
              height: '34px',
              width: '250px',
              textAlign: 'left',
              fontFamily: 'Mulish !important',
              fontSize: '14px',
              fontVariationSettings: '"wght" 400',
            }}
            onChange={changeLanguage}
            value={getFriendlyName(localStorage.getItem('i18nextLng') ?? selectedLanguage ?? 'en')}
          >
            {availableLanguages &&
              Object.keys(availableLanguages).map(lang => {
                return (
                  <MenuItem key={lang} value={getFriendlyName(lang)}>
                    {`${getFriendlyName(lang)}`}
                  </MenuItem>
                )
              })}
          </Select>
        </div>
      )}
    </>
  )
}

export default LanguageSelector

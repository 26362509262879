export interface TicketData {
  subject: string
  body: string
  attachment: FileList
  officeCode?: string
  whiteLabel?: number
  accountNumber?: string
}

export interface OfficeData {
  Office?: string
  AccountNumber?: string
}

export interface ContactSupportState {
  ticketData: TicketData | null
  contactSupportStatus: number
  showCustomerSupportModal: boolean
}

export const initialState: ContactSupportState = {
  ticketData: null,
  contactSupportStatus: 0,
  showCustomerSupportModal: false,
}

import { PayloadAction } from '@reduxjs/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'
import { initialState, TicketData } from '../Model/contactSupport.model'
import { contactSupportSaga } from './contactSupport.saga'
import { ProcessingStatus } from 'app/shared/models/models'
import { createSlice } from 'store/@reduxjs/toolkit'

const slice = createSlice({
  name: 'contactSupport',
  initialState,
  reducers: {
    setShowModalStatus(state, action: PayloadAction<boolean>) {
      state.showCustomerSupportModal = action.payload
    },
    sendSupportTicket(state, action: PayloadAction<TicketData>) {
      state.contactSupportStatus = ProcessingStatus.PENDING
    },
    resetSupportTicket(state) {
      state.ticketData = null
      state.contactSupportStatus = ProcessingStatus.RESET
    },
    supportTicketComplete(state) {
      state.contactSupportStatus = ProcessingStatus.COMPLETED
      state.ticketData = null
    },
    supportTicketError(state) {
      state.contactSupportStatus = ProcessingStatus.ERROR
      state.ticketData = null
    },
  },
})

export const { actions: ContactSupportActions, reducer } = slice

export const useContactSupportSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  useInjectSaga({ key: slice.name, saga: contactSupportSaga })
  return { actions: slice.actions }
}

import { PayloadAction } from '@reduxjs/toolkit'
import {
  EnableThirdPartyRequest,
  SoftwareSubSettings,
  ThirdPartyAccessType,
  ThirdPartyData,
  initialState,
} from '../Model'
import { ProcessingStatus } from 'app/shared/models/models'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'
import { thirdPartySaga } from './thirdparty.saga'
import { createSlice } from 'store/@reduxjs/toolkit'

const slice = createSlice({
  name: 'thirdParty',
  initialState,
  reducers: {
    getAccessType(state) {
      state.accessFetchStatus = ProcessingStatus.PENDING
    },
    accessTypeLoaded(state, action: PayloadAction<ThirdPartyAccessType>) {
      state.thirdPartyAccessType = action.payload
      state.accessFetchStatus = ProcessingStatus.COMPLETED
    },
    accessTypeError(state, action: PayloadAction<ThirdPartyAccessType | null>) {
      state.thirdPartyAccessType = action.payload
      state.accessFetchStatus = ProcessingStatus.ERROR
    },
    getThirdPartySubscriptions(state, _action: PayloadAction<number | null>) {
      state.subscriptionsFetchStatus = ProcessingStatus.PENDING
    },
    thirdPartySubscriptionsLoaded(state, action: PayloadAction<ThirdPartyData>) {
      state.thirdPartySubscriptions = action.payload
      state.subscriptionsFetchStatus = ProcessingStatus.COMPLETED
    },
    thirdPartySubscriptionsError(state, action: PayloadAction<string>) {
      state.thirdPartySubscriptions = null
      state.thirdPartyErrorMessage = action.payload
      state.subscriptionsFetchStatus = ProcessingStatus.ERROR
    },
    enableThirdParty(state, action: PayloadAction<EnableThirdPartyRequest>) {
      state.enableThirdPartyStatus = ProcessingStatus.PENDING
    },
    resetEnableThirdParty(state) {
      state.enableThirdPartyStatus = ProcessingStatus.RESET
    },
    resetEnableThirdPartyClearAll(state) {
      state.enableThirdPartyStatus = ProcessingStatus.RESET
      state.thirdPartySave = null
    },
    enableThirdPartyCompleted(state, action: PayloadAction<SoftwareSubSettings>) {
      state.enableThirdPartyStatus = ProcessingStatus.COMPLETED
      state.thirdPartySave = action.payload
    },
    enableThirdPartyError(state, action: PayloadAction<SoftwareSubSettings | null>) {
      state.enableThirdPartyStatus = ProcessingStatus.ERROR
      state.thirdPartySave = action.payload
    },
  },
})

export const { actions: thirdPartyActions, reducer } = slice

export const useThirdPartySlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  useInjectSaga({ key: slice.name, saga: thirdPartySaga })
  return { actions: slice.actions }
}

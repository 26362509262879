import { isMono } from '@mono/api'
import { useLocation, useSearchParams } from 'react-router-dom'

export const useCurrentLocation = () => {
  const location = useLocation()
  const [searchParams] = useSearchParams()

  if (isMono) {
    return searchParams.get('route')
  } else {
    return location.pathname
  }
}

import { createSelector } from '@reduxjs/toolkit'
import { initialState } from '../Models/auth-state-model'
import { RootState } from 'app/root-state'

const selectDomain = (state: RootState) => state.auth || initialState

export const selectLoggedInUser = createSelector([selectDomain], auth => auth.user)

export const selectUserStatus = createSelector([selectDomain], auth => auth.userFetchStatus)

export const selectAccessToken = createSelector([selectDomain], auth => auth.user?.access_token)

export const selectIdToken = createSelector([selectDomain], auth => auth.user?.id_token)

export const selectCdsId = createSelector([selectDomain], auth => auth.user?.profile?.cds_id)

export const selectUserType = createSelector([selectDomain], auth => auth.user?.profile?.user_type)

export const selectIsUserGainCustomer = createSelector(
  [selectDomain],
  auth => auth.userIsGainCustomer
)
export const selectIsUserGainCustomerFetchStatus = createSelector(
  [selectDomain],
  auth => auth.userIsGainCustomerFetchStatus
)
export const selecteUserIsBroker = createSelector([selectDomain], auth => auth.userIsBroker)
export const selectEnabledNavTabs = createSelector([selectDomain], auth => auth.userNavItems)

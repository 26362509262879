import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  ErrorType,
  ExchangePage,
  GridEntitlementKeyMap,
  GridProvider,
  MarketDataCardDetails,
  MarketDataFetchStatus,
  MarketDataPage,
  MarketDataSaveBillingBody,
  MarketDataSaveBillingErrorRes,
  MarketDataSaveBillingRes,
  PriceSumHelper,
  SaveMarketDataEntitlementsRes,
  SaveMarketDataEntitlementsResponseError,
  UnsubscibeEntitlementsState,
  UserCertType,
  UserPaymentChoice,
  initialState,
} from '../Model'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'
import { marketDataSaga } from './market-data.saga'
import { Country, State } from 'app/shared/models/models'
import { UserAccounts, UserAccountsResponse } from 'app/components/SoftwareSubscriptions/Model'

const slice = createSlice({
  name: 'marketData',
  initialState,
  reducers: {
    // ------------------------- Resets -----------------------------------------
    resetExchangePage(state) {
      state.PaymentMethod = initialState.PaymentMethod
      state.GridInitialSelections = initialState.GridInitialSelections
      state.GridUpdateSelections = initialState.GridUpdateSelections
      state.GridMatchOriginal = initialState.GridMatchOriginal
      state.ExchangePageConfigStatus = initialState.ExchangePageConfigStatus
      state.ExchangePageConfig = initialState.ExchangePageConfig
      state.ExchangePageConfigError = initialState.ExchangePageConfigError
      state.InitialMarketData = initialState.InitialMarketData
      state.SaveMarketDataEntitlementsRes = initialState.SaveMarketDataEntitlementsRes
      state.SaveMarketDataEntitlementsPostStatus = initialState.SaveMarketDataEntitlementsPostStatus
      state.SaveMarketDataEntitlementsResponseMessage =
        initialState.SaveMarketDataEntitlementsResponseMessage
      state.SaveMarketDataEntitlementsError = initialState.SaveMarketDataEntitlementsError
      state.FutureAccountSelected = initialState.FutureAccountSelected
      state.CreditCardCurrentBilling = initialState.CreditCardCurrentBilling
      state.CreditCardCurrentBillingError = initialState.CreditCardCurrentBillingError
      state.CreditCardCurrentBillingStatus = initialState.CreditCardCurrentBillingStatus
      state.CreditCardSaveBilling = initialState.CreditCardSaveBilling
      state.CreditCardSaveBillingStatus = initialState.CreditCardSaveBillingStatus
      state.CreditCardSaveBillingError = initialState.CreditCardSaveBillingError
      state.CreditCardWasUpdated = initialState.CreditCardWasUpdated
      state.CreditCardCountries = initialState.CreditCardCountries
      state.CreditCardCountriesStatus = initialState.CreditCardCountriesStatus
      state.CreditCardCountriesError = initialState.CreditCardCountriesError
      state.CreditCardStates = initialState.CreditCardStates
      state.CreditCardStatesStatus = initialState.CreditCardStatesStatus
      state.CreditCardStatesError = initialState.CreditCardStatesError
      state.initialCostData = initialState.initialCostData
      state.updatedCostData = initialState.updatedCostData
      state.UserCertified = initialState.UserCertified
      state.UserCertifiedFetchStatus = initialState.UserCertifiedFetchStatus
      state.UserCertifiedError = initialState.UserCertifiedError
      state.UnsubscibeEntitlements = initialState.UnsubscibeEntitlements
      state.UnsubscibeEntitlementsStatus = initialState.UnsubscibeEntitlementsStatus
    },
    // -------------------------/End Resets -----------------------------------------

    setMarketDataPageToRender(state, action: PayloadAction<MarketDataPage>) {
      state.MarketDataPage = action.payload
    },
    setTermsAccepted(state, action: PayloadAction<boolean>) {
      state.MarketDataTermsAccepted = action.payload
    },
    setPaymentMethod(state, action: PayloadAction<UserPaymentChoice>) {
      state.PaymentMethod = action.payload
    },

    getUserCertified(state) {
      state.UserCertifiedFetchStatus = MarketDataFetchStatus.Loading
      state.UserCertifiedError = null
    },
    postUserCertified(state, action: PayloadAction<UserCertType>) {
      state.UserCertifiedFetchStatus = MarketDataFetchStatus.Loading
      state.UserCertified = action.payload
      state.UserCertifiedError = null
    },
    setUserCertified(state, action: PayloadAction<UserCertType>) {
      state.UserCertifiedFetchStatus = MarketDataFetchStatus.Success
      state.UserCertified = action.payload
      state.UserCertifiedError = null
    },
    setUserCertifiedError(state, action: PayloadAction<ErrorType>) {
      state.UserCertifiedFetchStatus = MarketDataFetchStatus.Error
      state.UserCertifiedError = action.payload
    },
    getExchangePageData(state) {
      state.ExchangePageConfigStatus = MarketDataFetchStatus.Loading
      state.ExchangePageConfigError = null
    },
    getCustOfBrokerExchangePageData(state, _action: PayloadAction<number>) {
      state.ExchangePageConfigStatus = MarketDataFetchStatus.Loading
      state.ExchangePageConfigError = null
    },
    setExchangePageConfig(state, action: PayloadAction<GridProvider[]>) {
      state.ExchangePageConfigStatus = MarketDataFetchStatus.Success
      state.ExchangePageConfig = action.payload
      state.ExchangePageConfigError = null
    },
    setInitialMarketDataObj(state, action: PayloadAction<ExchangePage>) {
      state.InitialMarketData = action.payload
    },
    setExchangePageConfigError(state, action: PayloadAction<ErrorType>) {
      state.ExchangePageConfigStatus = MarketDataFetchStatus.Error
      state.ExchangePageConfigError = action.payload
    },
    postSaveMarketDataEntitlements(state) {
      state.SaveMarketDataEntitlementsRes = null
      state.SaveMarketDataEntitlementsPostStatus = MarketDataFetchStatus.Loading
      state.SaveMarketDataEntitlementsError = null
    },
    setSaveMarketDataEntitlements(state, action: PayloadAction<SaveMarketDataEntitlementsRes>) {
      state.SaveMarketDataEntitlementsRes = action.payload
      state.SaveMarketDataEntitlementsPostStatus = MarketDataFetchStatus.Success
      state.SaveMarketDataEntitlementsError = null
    },
    setSaveMarketDataEntitlementsResponseMessage(state, action: PayloadAction<string[]>) {
      state.SaveMarketDataEntitlementsResponseMessage = action.payload
    },
    setSaveMarketDataEntitlementsError(
      state,
      action: PayloadAction<SaveMarketDataEntitlementsResponseError>
    ) {
      state.SaveMarketDataEntitlementsRes = action.payload.response
      state.SaveMarketDataEntitlementsPostStatus = MarketDataFetchStatus.Error
      state.SaveMarketDataEntitlementsError = action.payload.errorType
    },

    postUnsubscibeMarketDataEntitlements(state) {
      state.UnsubscibeEntitlementsStatus = MarketDataFetchStatus.Loading
    },
    setUnsubscibeMarketDataEntitlementsSuccess(state) {
      state.UnsubscibeEntitlementsStatus = MarketDataFetchStatus.Success
    },
    setUnsubscibeState(state, action: PayloadAction<UnsubscibeEntitlementsState>) {
      state.UnsubscibeEntitlements = action.payload
    },
    setUnsubscibeMarketDataEntitlementsError(state) {
      state.UnsubscibeEntitlementsStatus = MarketDataFetchStatus.Error
    },

    // ------------------------- App State Helpers -----------------------------------------

    setInitialMarketDataSelections(state, action: PayloadAction<GridEntitlementKeyMap[]>) {
      state.GridInitialSelections = action.payload
      state.GridUpdateSelections = action.payload
    },
    updateUserGridSelection(_state, _action: PayloadAction<GridEntitlementKeyMap[]>) {},
    setUpdateUserGridSelections(state, action: PayloadAction<GridEntitlementKeyMap[]>) {
      state.GridUpdateSelections = action.payload
    },
    setGridHasUpdated(state, action: PayloadAction<boolean>) {
      state.GridMatchOriginal = action.payload
    },

    setInitialCostData(state, action: PayloadAction<PriceSumHelper>) {
      state.initialCostData = action.payload
    },
    setUpdatedCostData(state, action: PayloadAction<PriceSumHelper>) {
      state.updatedCostData = action.payload
    },
    setGridReadOnly(state, action: PayloadAction<boolean>) {
      state.IsReadOnly = action.payload
    },
    setIsLastThreeDayOrLess(state, action: PayloadAction<boolean>) {
      state.IsLastThreeDayOrLess = action.payload
    },
    // ------------------------- /End App State Helpers -----------------------------------------

    // ------------------------- Credit Card Billing -----------------------------------------
    getCreditCardCurrentBilling(state) {
      state.CreditCardCurrentBilling = null
      state.CreditCardCurrentBillingStatus = MarketDataFetchStatus.Loading
      state.CreditCardCurrentBillingError = null
      state.CreditCardCountries = []
      state.CreditCardCountriesStatus = MarketDataFetchStatus.Loading
      state.CreditCardCountriesError = null
      state.CreditCardStates = []
      state.CreditCardStatesStatus = MarketDataFetchStatus.Loading
      state.CreditCardStatesError = null
    },
    setCreditCardCurrentBilling(state, action: PayloadAction<MarketDataCardDetails>) {
      state.CreditCardCurrentBilling = action.payload
      state.CreditCardCurrentBillingStatus = MarketDataFetchStatus.Success
      state.CreditCardCurrentBillingError = null
    },

    setCreditCardCountries(state, action: PayloadAction<Country[]>) {
      state.CreditCardCountries = action.payload
      state.CreditCardCountriesStatus = MarketDataFetchStatus.Success
      state.CreditCardCountriesError = null
    },
    getNewCreditCardStates(state, _action: PayloadAction<number>) {
      state.CreditCardStates = []
      state.CreditCardStatesStatus = MarketDataFetchStatus.Loading
      state.CreditCardStatesError = null
    },
    setCreditCardStates(state, action: PayloadAction<State[]>) {
      state.CreditCardStates = action.payload
      state.CreditCardStatesStatus = MarketDataFetchStatus.Success
      state.CreditCardStatesError = null
    },
    setCreditCardCurrentBillingError(state, action: PayloadAction<ErrorType>) {
      state.CreditCardCurrentBilling = null
      state.CreditCardCurrentBillingStatus = MarketDataFetchStatus.Error
      state.CreditCardCurrentBillingError = action.payload
      state.CreditCardCountries = []
      state.CreditCardCountriesStatus = MarketDataFetchStatus.Error
      state.CreditCardCountriesError = ErrorType.ResponseError
      state.CreditCardStates = []
      state.CreditCardStatesStatus = MarketDataFetchStatus.Error
      state.CreditCardStatesError = ErrorType.ResponseError
    },

    postCreditCardSaveBilling(state, _action: PayloadAction<MarketDataSaveBillingBody>) {
      state.CreditCardSaveBilling = null
      state.CreditCardSaveBillingStatus = MarketDataFetchStatus.Loading
    },
    setCreditCardSaveBillingSuccess(state, action: PayloadAction<MarketDataSaveBillingRes>) {
      state.CreditCardSaveBilling = action.payload
      state.CreditCardSaveBillingStatus = MarketDataFetchStatus.Success
    },
    setCreditCardSaveBillingError(state, action: PayloadAction<MarketDataSaveBillingErrorRes>) {
      state.CreditCardSaveBillingStatus = MarketDataFetchStatus.Error
      state.CreditCardSaveBilling = action.payload.response
      state.CreditCardSaveBillingError = action.payload.error
    },
    setCreditCardWasUpdated(state, action: PayloadAction<boolean>) {
      state.CreditCardWasUpdated = action.payload
    },
    setSendCCEmailReceipt(state, action: PayloadAction<boolean>) {
      state.CreditCardEmailReceipt = action.payload
    },
    // ------------------------- /End Credit Card Billing -----------------------------------------
    // ------------------------- Futures Accounts -----------------------------------------
    getFuturesAccounts(state) {
      state.FuturesAccountsFetchStatus = MarketDataFetchStatus.Loading
      state.FuturesAccountsError = null
    },
    setFuturesAccounts(state, action: PayloadAction<UserAccountsResponse>) {
      state.FuturesAccountsFetchStatus = MarketDataFetchStatus.Success
      state.FuturesAccountResponse = action.payload
      state.FuturesAccountsError = null
    },
    setFutureAccountSelected(state, action: PayloadAction<UserAccounts>) {
      state.FutureAccountSelected = action.payload
    },
    setFuturesAccountsError(state, action: PayloadAction<ErrorType>) {
      state.FuturesAccountsFetchStatus = MarketDataFetchStatus.Error
      state.FuturesAccountsError = action.payload
    },
    // ------------------------- /End Futures Accounts -----------------------------------------
  },
})

export const { actions: MarketDataActions, reducer } = slice

export const useMarketDataSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  useInjectSaga({ key: slice.name, saga: marketDataSaga })
  return { actions: slice.actions }
}

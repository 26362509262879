import ApiService from 'app/components/ProfileManagement/Helper/api-service'
import { SimpleIdName, State } from '../models/models'
import { GET_COUNTRIES, GET_STATES } from 'app/components/ProfileManagement/Helper/constants'

export const GetCountriesAPI = async (): Promise<SimpleIdName> => {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const ENDPOINT = `${BASE_URL}${GET_COUNTRIES}`
  const response = await ApiService.get(ENDPOINT).catch(function (error) {
    throw error
  })
  return response?.data
}

export const GetStatesAPI = async (countryId: Number): Promise<State> => {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const ENDPOINT = `${BASE_URL}${GET_STATES}`
  const response = await ApiService.get(ENDPOINT + `?countryId=${countryId}`).catch(function (
    error
  ) {
    throw error
  })
  return response?.data
}

import { CreditCardDetails } from 'app/components/ReusableComponents/CreditCard/Model'
import { translate } from 'app/shared/localization/helper/localization-helper'
import { ProcessingStatus, SimpleIdName } from 'app/shared/models/models'

export interface ActiveSubscriptionAPI {
  software: SubscriptionsData[]
  warning: SubscriptionWarning
}

export enum Render_RowTypes {
  DEFAULT_ROW = 1,
  PINNED_ROW = 2,
  TOTAL_ROW = 3,
}

export interface SubscriptionsData {
  id: number
  name: string
  description: string
  appTitle: string
  monthlyCost: string
  MonthlyCostCalc: string
  perTradeCost: string
  maxCost: string
  adminFee: string
  adminFeeCalc: string
  creditCardFeeCalc: string
  totalMonthlyCost: string
  totalMonthlyCostWithCC: string
  isCurrentSubscription: boolean
  isCurrentStatus: boolean
  dataType: number
}

export interface SubscriptionWarning {
  warningReason: string
  warningMessage: string
}

export const defaultPendingRequst = {
  warningReason: 'PENDING',
  warningMessage: `${translate(
    't__ChangePending',
    'A change in subscription is pending',
    'Messages'
  )}`,
}

export const Warning_Reason = {
  NONE: 'NONE',
  PENDING: 'PENDING',
  LASTDAY: 'LASTDAY',
  THREEDAYS: 'THREEDAYS',
}

export enum ChangeModalType {
  CHANGE_SUBSCRIPTION = 1,
  UNSUBSCRIBE_SUBSCRIPTION = 2,
}

export enum UILabel {
  subscribed = 1,
  unsubscribed = 2,
  error = 3,
}

export enum PaymentPlanType {
  BILLING_ACCOUNT = 1,
  CREDIT_CARD = 3,
}

export interface ChangeModalDetails {
  title: string
  titleTranslationKey: string
  text: string
  textTranslationKey: string
  modalType: number | null
}

export const newSubscriptionDetails: ChangeModalDetails = {
  title: 'New Subscription?',
  titleTranslationKey: 't__NewSubscription',
  text: 'Selection of new software will cancel your existing software subscription.',
  textTranslationKey: 't__SubscriptionCancelOld',
  modalType: ChangeModalType.CHANGE_SUBSCRIPTION,
}

export const unsubscribeSubscriptionDetails: ChangeModalDetails = {
  title: 'Unsubscribe?',
  titleTranslationKey: 't__Unsubscribe',
  text: 'Are you sure you want to cancel your subscription?',
  textTranslationKey: 't__CancelSoftSub',
  modalType: ChangeModalType.UNSUBSCRIBE_SUBSCRIPTION,
}

export interface UserAccountsResponse {
  isSuccess: boolean
  errorMessage: string
  accounts: UserAccounts[]
}
export interface UserAccounts {
  id: number
  accountNumber: string
  accountName: string
  managementType: string
  paidSoftwareFlag: boolean
  readOnly: boolean
  isManagedAccount: boolean
  whiteLabelId?: number
  officeCode?: string
}

export interface NewSubscriptionDataObj {
  accountNumbers: string[] | []
  softwareId: number | null
  planType: number | null
  accountIdForDebit: string | null
  isRenewing: boolean
  emailCCReceipt: boolean
}

const initalNewSubDataObj: NewSubscriptionDataObj = {
  accountNumbers: null,
  softwareId: null,
  planType: null,
  accountIdForDebit: null,
  isRenewing: false,
  emailCCReceipt: false,
}

export interface SubscriptionsState {
  termsModal: boolean
  subscriptionsData: SubscriptionsData[] | null
  subscriptionWarning: SubscriptionWarning | null
  subscriptionError: string | null
  userAccountResponse: UserAccountsResponse | null
  newSubscriptionDataObj: NewSubscriptionDataObj
  storedChangedSubscriptionData: SubscriptionsData[] | null
  newSubscriptionData: SubscriptionsData | null
  existingCCDetails: CreditCardDetails | null
  ccTypes: SimpleIdName | null
  showChangeSubscriptionsPage: boolean
  subscriptionProcessingStatus: number
  userAccountFetchStatus: ProcessingStatus
  uiLabel: number
}

export const initialState: SubscriptionsState = {
  termsModal: false,
  subscriptionsData: null,
  subscriptionWarning: null,
  subscriptionError: null,
  userAccountResponse: null,
  newSubscriptionDataObj: initalNewSubDataObj,
  newSubscriptionData: null,
  subscriptionProcessingStatus: 0,
  storedChangedSubscriptionData: null,
  existingCCDetails: null,
  ccTypes: null,
  showChangeSubscriptionsPage: false,
  userAccountFetchStatus: ProcessingStatus.RESET,
  uiLabel: 0,
}

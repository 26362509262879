export interface LocalizationState {
  AvailableLanguages: Object | null
  SelectedLanguage: string
}

export const initialState: LocalizationState = {
  AvailableLanguages: {
    en: { nativeName: 'English' },
  },
  SelectedLanguage: 'en',
}

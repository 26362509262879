import i18next from '../../../initialization/i18n'

export const translate = (key: string, defaultValue: string, namespace: string = 'translation') => {
  return i18next.t(key, {
    defaultValue: `${defaultValue}`,
    ns: `${namespace}`,
  })
}

export interface FriendlyLanguageCode {
  locizeCode: string
  friendlyName: string
}

export const mappedLanguageCodes: FriendlyLanguageCode[] = [
  {
    locizeCode: 'en',
    friendlyName: 'English',
  },
  {
    locizeCode: 'es',
    friendlyName: 'Spanish',
  },
  {
    locizeCode: 'pt-BR',
    friendlyName: 'Portuguese',
  },
]

export const getLocizeCode = (name: string) => {
  return mappedLanguageCodes.find(x => x.friendlyName === name)?.locizeCode || 'en'
}

export const getFriendlyName = (code: string) => {
  return mappedLanguageCodes.find(x => x.locizeCode === code)?.friendlyName || 'English'
}

import { call, put, select, takeLatest } from 'redux-saga/effects'
import { MarketDataActions as actions } from './market-data.slice'
import {
  getCurrentBillingAPI,
  getExchangePageAPI,
  getUserCertfiedAPI,
  getUserLiveAccountsAPI,
  postSaveBillingAPI,
  submitUserCertfiedAPI,
  sumbitSaveMarketDataEntitlementsAPI,
} from '../Services/market-data-service'
import {
  CurrentBillingResponse,
  ErrorType,
  ExchangePage,
  ExchangePageResponse,
  GridEntitlementKeyMap,
  MarketDataSaveBillingBody,
  PriceSumHelper,
  SaveMarketDataEntitlementsBody,
  SaveMarketDataEntitlementsResponse,
  UnsubscibeEntitlementsState,
  UserCertType,
  UserCertifiedUpdateResponse,
  UserPaymentChoice,
} from '../Model'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  selectFutureAccountChoice,
  selectGridUserSelections,
  selectInitialMarketData,
  selectInitialUserSelections,
  selectPaymentMethod,
  selectSendCCEmailReceipt,
} from './market-data.selectors'
import {
  calcCostInformation,
  determineDataMatch,
  getFormattedDataProviders,
  getGridKeyMappings,
  getNewUserUpdates,
  getUnsubscibeState,
  mapCurrentBillingToDetails,
  mapUnsubscribeEntitlements,
  mapUpdatesToMarketDataObj,
  mergeUnsubscirbedProviders,
} from '../Helpers/service-helpers'
import { Country, State } from 'app/shared/models/models'
import { GetCountriesAPI, GetStatesAPI } from 'app/shared/services'
import { UserAccounts, UserAccountsResponse } from 'app/components/SoftwareSubscriptions/Model'
import { selecteUserIsBroker } from 'app/authorization/Slice/auth-selectors'

export function* getIsUserCertified() {
  try {
    const response: UserCertType = yield call(getUserCertfiedAPI)
    if (response) {
      yield put(actions.setUserCertified(response))
      if (response === UserCertType.Professional || response === UserCertType.NonProfessional) {
        yield put(actions.getExchangePageData())
      }
    } else {
      yield put(actions.setUserCertified(UserCertType.NotSelected))
    }
  } catch {
    yield put(actions.setUserCertifiedError())
  }
}

export function* submitUserCertSelection(action: PayloadAction<UserCertType>) {
  const userCertChoice = action.payload
  try {
    const response: UserCertifiedUpdateResponse = yield call(submitUserCertfiedAPI, userCertChoice)
    if (response) {
      yield put(actions.setUserCertified(userCertChoice))
      if (
        userCertChoice === UserCertType.Professional ||
        userCertChoice === UserCertType.NonProfessional
      ) {
        yield put(actions.getExchangePageData())
      }
    }
  } catch {
    yield put(actions.setUserCertifiedError())
  }
}

export function* getCustomerOfBrokerExchangePageData(action: PayloadAction<number>) {
  try {
    const { data }: ExchangePageResponse = yield call(getExchangePageAPI, action.payload)
    if (data) {
      const providers = yield getFormattedDataProviders(data)
      const gridKeyMappings = yield getGridKeyMappings(providers)
      if (data.professional) {
        yield put(actions.setUserCertified(UserCertType.Professional))
      } else {
        yield put(actions.setUserCertified(UserCertType.NonProfessional))
      }
      const costData: PriceSumHelper = {
        adminFee: data.adminFee ? data.adminFee / 100 : null,
        ccFee: data.ccFee ? data.ccFee / 100 : null,
      }
      const costSumData = calcCostInformation(
        gridKeyMappings,
        costData,
        UserPaymentChoice.NonSelected
      )
      if (data.hasActiveDataPlanWithCC) {
        yield put(actions.setPaymentMethod(UserPaymentChoice.CreditCard))
      }
      if (data.hasActiveDataPlanWithAccount) {
        yield put(actions.setPaymentMethod(UserPaymentChoice.FuturesAccount))
      }
      yield put(actions.setGridReadOnly(true))
      yield put(actions.setInitialMarketDataObj(data))
      yield put(actions.setExchangePageConfig(providers))
      yield put(actions.setInitialMarketDataSelections(gridKeyMappings))
      yield put(actions.setInitialCostData(costSumData))
    } else {
      yield put(actions.setExchangePageConfig(null))
      yield put(actions.setExchangePageConfigError(ErrorType.NotFound))
    }
  } catch (e) {
    yield put(actions.setExchangePageConfigError(ErrorType.ResponseError))
  }
}

export function* getExchangePageData() {
  try {
    const { data }: ExchangePageResponse = yield call(getExchangePageAPI)
    if (data) {
      const providers = yield getFormattedDataProviders(data)
      const gridKeyMappings: GridEntitlementKeyMap[] = yield getGridKeyMappings(providers)
      const unsubscibeState: UnsubscibeEntitlementsState = yield getUnsubscibeState(gridKeyMappings)
      const broker = yield select(selecteUserIsBroker)
      const costData: PriceSumHelper = {
        adminFee: data.adminFee ? data.adminFee / 100 : null,
        ccFee: data.ccFee ? data.ccFee / 100 : null,
      }
      const costSumData = calcCostInformation(
        gridKeyMappings,
        costData,
        UserPaymentChoice.NonSelected
      )
      if (data.hasActiveDataPlanWithCC) {
        yield put(actions.setPaymentMethod(UserPaymentChoice.CreditCard))
      }
      if (data.hasActiveDataPlanWithAccount && !broker) {
        yield put(actions.setPaymentMethod(UserPaymentChoice.FuturesAccount))
      }
      if (data.hasActiveDataPlanWithAccount && broker) {
        yield put(actions.setPaymentMethod(UserPaymentChoice.IbPayout))
      }
      yield put(actions.setGridReadOnly(false))
      yield put(actions.setIsLastThreeDayOrLess(data.isLastThreeDayOrLess))
      yield put(actions.setUnsubscibeState(unsubscibeState))
      yield put(actions.setInitialMarketDataObj(data))
      yield put(actions.setExchangePageConfig(providers))
      yield put(actions.setInitialMarketDataSelections(gridKeyMappings))
      yield put(actions.setInitialCostData(costSumData))
    } else {
      yield put(actions.setExchangePageConfig(null))
      yield put(actions.setExchangePageConfigError(ErrorType.NotFound))
    }
  } catch (e) {
    yield put(actions.setExchangePageConfigError(ErrorType.ResponseError))
  }
}

export function* submitSaveMarketDataEntitlementsData() {
  const initialMarketData: ExchangePage = yield select(selectInitialMarketData)
  const newUserSelections: GridEntitlementKeyMap[] = yield select(selectGridUserSelections)
  const PaymentMethod: UserPaymentChoice = yield select(selectPaymentMethod)
  const futureAccount: UserAccounts = yield select(selectFutureAccountChoice)
  const ccEmailReceipt: boolean = yield select(selectSendCCEmailReceipt)
  try {
    const updatedMarketData: SaveMarketDataEntitlementsBody = mapUpdatesToMarketDataObj(
      initialMarketData,
      newUserSelections,
      PaymentMethod,
      futureAccount,
      ccEmailReceipt
    )
    const response: SaveMarketDataEntitlementsResponse = yield call(
      sumbitSaveMarketDataEntitlementsAPI,
      updatedMarketData
    )
    if (response && response.status === 200) {
      if (response.data?.prodResults?.length) {
        const prodFailureArr = response.data.prodResults.reduce((s: string[], c) => {
          if (!c.ok) {
            s.push(`${response.data.prodAccountNumber}: ${c.errorText}`)
          }
          return s
        }, [])

        const prodFailedToUpdate = prodFailureArr.filter((v, i, a) => a.indexOf(v) === i)
        if (prodFailureArr.length === 0) {
          yield put(actions.setSaveMarketDataEntitlements(response.data))
        } else if (prodFailureArr.length !== response.data.prodResults.length) {
          yield put(actions.setSaveMarketDataEntitlements(response.data))
          yield put(actions.setSaveMarketDataEntitlementsResponseMessage(prodFailedToUpdate))
        } else {
          yield put(
            actions.setSaveMarketDataEntitlementsError({
              errorType: ErrorType.GeneralError,
              response: null,
            })
          )
          const mergeEntMsgToFailures = [
            ...prodFailedToUpdate,
            response.data.returnEntitlementsMessage,
          ]
          yield put(actions.setSaveMarketDataEntitlementsResponseMessage(mergeEntMsgToFailures))
        }
      } else {
        if (response.data?.ccResponse?.error) {
          const errorCode = response.data.ccResponse.error.errorCode
          const errorMsg = response.data.ccResponse.error.errorMessage
          if (errorCode) {
            actions.setSaveMarketDataEntitlementsResponseMessage([`${errorMsg}`])
          } else {
            actions.setSaveMarketDataEntitlementsResponseMessage(['Entitlements unable to update'])
          }
        }
        yield put(
          actions.setSaveMarketDataEntitlementsError({
            errorType: ErrorType.NotFound,
            response: response.data,
          })
        )
      }
    } else {
      yield put(
        actions.setSaveMarketDataEntitlementsError({
          errorType: ErrorType.StatusError,
          response: null,
        })
      )
      yield put(actions.setSaveMarketDataEntitlementsResponseMessage(['Update request failed']))
    }
  } catch (e) {
    yield put(
      actions.setSaveMarketDataEntitlementsError({
        errorType: ErrorType.ResponseError,
        response: null,
      })
    )
    yield put(actions.setSaveMarketDataEntitlementsResponseMessage(['Server error']))
  }
}
export function* submitUnsubscribeSaveMarketDataEntitlementsData() {
  const initialMarketData: ExchangePage = yield select(selectInitialMarketData)
  const PaymentMethod: UserPaymentChoice = yield select(selectPaymentMethod)
  const futureAccount: UserAccounts = yield select(selectFutureAccountChoice)

  try {
    const updatedMarketData: SaveMarketDataEntitlementsBody = mapUnsubscribeEntitlements(
      initialMarketData,
      PaymentMethod,
      futureAccount
    )

    const response = yield call(sumbitSaveMarketDataEntitlementsAPI, updatedMarketData)
    if (response && response.status === 200) {
      const updateExchangePage: ExchangePage = mergeUnsubscirbedProviders(
        initialMarketData,
        updatedMarketData.dataProviders
      )
      const updateProviders = getFormattedDataProviders(updateExchangePage)
      const gridKeyMappings: GridEntitlementKeyMap[] = yield getGridKeyMappings(updateProviders)
      const costData: PriceSumHelper = {
        adminFee: updateExchangePage.adminFee ? updateExchangePage.adminFee / 100 : null,
        ccFee: updateExchangePage.ccFee ? updateExchangePage.ccFee / 100 : null,
      }
      const costSumData = calcCostInformation(
        gridKeyMappings,
        costData,
        UserPaymentChoice.NonSelected
      )
      yield put(actions.setUnsubscibeState(UnsubscibeEntitlementsState.Complete))
      yield put(actions.setUnsubscibeMarketDataEntitlementsSuccess())
      yield put(actions.setSaveMarketDataEntitlements(response.data))
      //---------------------------------------------------------------------------
      yield put(actions.setGridReadOnly(false))
      yield put(actions.setInitialMarketDataObj(updateExchangePage))
      yield put(actions.setExchangePageConfig(updateProviders))
      yield put(actions.setInitialMarketDataSelections(gridKeyMappings))
      yield put(actions.setInitialCostData(costSumData))
      //---------------------------------------------------------------------------
    } else {
      yield put(actions.setUnsubscibeMarketDataEntitlementsError())
      yield put(actions.setUnsubscibeState(UnsubscibeEntitlementsState.Complete))
    }
  } catch (e) {
    yield put(actions.setUnsubscibeMarketDataEntitlementsError())
    yield put(actions.setUnsubscibeState(UnsubscibeEntitlementsState.Complete))
  }
}

export function* getCurrentBillingData() {
  try {
    const countries: Country[] = yield call(GetCountriesAPI)
    const response: CurrentBillingResponse = yield call(getCurrentBillingAPI)
    if (response && response.status === 200) {
      if (response.data && response.data.hasProfile) {
        const states: State[] = yield call(GetStatesAPI, response.data.countryId)
        const billCountry = countries.find(x => x.id === response.data.countryId)
        const billState = states.find(x => x.id === response.data.stateId)
        yield put(
          actions.setCreditCardCurrentBilling(
            mapCurrentBillingToDetails(response.data, billCountry, billState)
          )
        )
        yield put(actions.setCreditCardCountries(countries))
        yield put(actions.setCreditCardStates(states))
      } else {
        const states: State[] = yield call(GetStatesAPI, 255)
        yield put(actions.setCreditCardCurrentBilling(mapCurrentBillingToDetails(null, null, null)))
        yield put(actions.setCreditCardCountries(countries))
        yield put(actions.setCreditCardStates(states))
      }
    } else {
      yield put(actions.setCreditCardCurrentBillingError(ErrorType.NotFound))
    }
  } catch {
    yield put(actions.setCreditCardCurrentBillingError(ErrorType.ResponseError))
  }
}

export function* getStates(action: PayloadAction<number>) {
  try {
    const states: State[] = yield call(GetStatesAPI, action.payload)
    if (states.length) {
      yield put(actions.setCreditCardStates(states))
    } else {
      yield put(actions.setCreditCardStates([]))
    }
  } catch {
    yield put(actions.setCreditCardStates([]))
  }
}

export function* submitSaveBilling(action: PayloadAction<MarketDataSaveBillingBody>) {
  try {
    yield put(actions.setCreditCardWasUpdated(true))
    const response = yield call(postSaveBillingAPI, action.payload)
    if (response && response.data) {
      if (response.status === 200 && response.data.isSuccess) {
        yield put(actions.setCreditCardSaveBillingSuccess(response.data))
        yield put(actions.getCreditCardCurrentBilling())
        yield put(actions.postSaveMarketDataEntitlements())
      } else {
        yield put(
          actions.setCreditCardSaveBillingError({
            error: ErrorType.StatusError,
            response: response.data,
          })
        )
      }
    } else {
      yield put(
        actions.setCreditCardSaveBillingError({ error: ErrorType.NotFound, response: null })
      )
    }
  } catch (e) {
    yield put(
      actions.setCreditCardSaveBillingError({ error: ErrorType.ResponseError, response: null })
    )
  }
}

export function* getFuturesAccountsData() {
  try {
    const data: UserAccountsResponse = yield call(getUserLiveAccountsAPI)
    if (data.accounts?.length) {
      yield put(actions.setFuturesAccounts(data))
    } else {
      yield put(actions.setFuturesAccounts(null))
    }
  } catch (e) {
    yield put(actions.setFuturesAccountsError(ErrorType.ResponseError))
  }
}

export function* updateUserSelectedMarketData(action: PayloadAction<GridEntitlementKeyMap[]>) {
  const initialData: GridEntitlementKeyMap[] = yield select(selectInitialUserSelections)
  const userUpdates: GridEntitlementKeyMap[] = yield select(selectGridUserSelections)
  const newKeyMaps = action.payload
  const updateData = yield getNewUserUpdates(userUpdates, newKeyMaps)
  const dataMatchesOriginal = yield determineDataMatch(initialData, updateData)
  yield put(actions.setUpdateUserGridSelections(updateData))
  yield put(actions.setGridHasUpdated(dataMatchesOriginal))
}

export function* marketDataSaga() {
  yield takeLatest(actions.getUserCertified, getIsUserCertified)
  yield takeLatest(actions.getExchangePageData, getExchangePageData)
  yield takeLatest(
    actions.getCustOfBrokerExchangePageData.type,
    getCustomerOfBrokerExchangePageData
  )
  yield takeLatest(actions.postSaveMarketDataEntitlements, submitSaveMarketDataEntitlementsData)
  yield takeLatest(
    actions.postUnsubscibeMarketDataEntitlements,
    submitUnsubscribeSaveMarketDataEntitlementsData
  )
  yield takeLatest(actions.postUserCertified.type, submitUserCertSelection)
  yield takeLatest(actions.getCreditCardCurrentBilling, getCurrentBillingData)
  yield takeLatest(actions.getNewCreditCardStates.type, getStates)
  yield takeLatest(actions.postCreditCardSaveBilling.type, submitSaveBilling)
  yield takeLatest(actions.updateUserGridSelection.type, updateUserSelectedMarketData)
  yield takeLatest(actions.getFuturesAccounts, getFuturesAccountsData)
}

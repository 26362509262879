import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../../root-state'
import { initialState } from '../model/localization.state'

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.localization || initialState

export const selectAvailableLanguages = createSelector(
  [selectDomain],
  flag => flag.AvailableLanguages
)

export const selectSelectedLanguage = createSelector([selectDomain], flag => flag.SelectedLanguage)

import React, { useEffect } from 'react'
import styles from './customerSearch.module.scss'
import SearchInputBox from './Components/searchInputBox'
import CustomerCard from './Components/customerCard'
import { useDispatch, useSelector } from 'react-redux'
import { customerOfBrokerListStatus } from './Slice/customerSearch.selector'
import { CustomerListFetchStatus } from './Model'
import { useCustomerListSlice } from './Slice/customerSearch.slice'
import { selectMarketDataPage } from '../MarketDataSubsciptions/Slice/market-data.selectors'
import { MarketDataPage } from '../MarketDataSubsciptions/Model'

const CustomerSearch: React.FC = () => {
  const customerListStatus = useSelector(customerOfBrokerListStatus)
  const marketDataSubsciptionsPage = useSelector(selectMarketDataPage)
  const dispatch = useDispatch()
  const { actions } = useCustomerListSlice()

  useEffect(() => {
    if (customerListStatus === CustomerListFetchStatus.Initial) {
      dispatch(actions.getCustomerListLoad())
    }
  }, [customerListStatus])

  return (
    <div
      className={
        marketDataSubsciptionsPage === MarketDataPage.PaymentSelection ||
        marketDataSubsciptionsPage === MarketDataPage.FuturesAccountResponse
          ? styles.hideInput
          : styles.parent
      }
    >
      <div className={styles.child}>
        <SearchInputBox />
      </div>
      <div className={styles.child}>
        <CustomerCard />
      </div>
    </div>
  )
}

export default CustomerSearch

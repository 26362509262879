import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { signinRedirectCallback } from './user-service'
import { Spinner } from '../components/ReusableComponents/spinner'
import { useSelector } from 'react-redux'
import { selectUserStatus } from './Slice/auth-selectors'
import { UserFetchStatus } from './Models/user-fetch-status.enum'

function SigninOidc() {
  const userStatus = useSelector(selectUserStatus)
  const navigate = useNavigate()

  useEffect(() => {
    async function signinAsync() {
      const redirectURLPath = sessionStorage.getItem('auth:redirect') || '/'

      if (userStatus !== UserFetchStatus.Loaded) {
        await signinRedirectCallback(redirectURLPath)
      }

      navigate(redirectURLPath, { replace: true })
    }
    signinAsync()
  }, [userStatus])

  return <Spinner />
}

export default SigninOidc

import { call, put, takeLatest } from 'redux-saga/effects'
import { getCustomerListAPI } from '../Service/customerSearch.service'
import { customerListActions as actions } from './customerSearch.slice'
import { CustomerOfBroker, GetCustomerOfBrokerResponse } from '../Model'

export function* getCustomerList() {
  try {
    const { data }: GetCustomerOfBrokerResponse = yield call(getCustomerListAPI)
    if (data?.length) {
      const formatted: CustomerOfBroker[] = data.map((x: CustomerOfBroker) => {
        const customer = { ...x }
        customer.id = x.id
        customer.firstName = x.firstName
        customer.lastName = x.lastName
        customer.customerEmail = x.customerEmail
        customer.accountName = x.accountName
        customer.customerUserName = x.customerUserName
        customer.demo = x.demo

        return customer
      })
      yield put(actions.getCustomerListLoaded(formatted))
    } else {
      yield put(actions.getCustomerListError())
    }
  } catch (err) {
    yield put(actions.getCustomerListError())
  }
}

export function* customerListSaga() {
  yield takeLatest(actions.getCustomerListLoad, getCustomerList)
}

import * as React from 'react'
import ButtonAppBar from '../ProfileManagement/AppBar/appBar'
import { isMono } from '@mono/api'
import styles from './appLayout.module.scss'
import NavigationPanelMobile from '../NavigationPanel/navigationPanelMobile'
import NavigationPanel from '../NavigationPanel/navigationPanel'
import { Outlet } from 'react-router-dom'
import { selectLoggedInUser, selecteUserIsBroker } from 'app/authorization/Slice/auth-selectors'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useCurrentLocation } from 'app/shared/hooks/useCurrentLocation'
import {
  FUTURES_TRADING_SOFTWARE_SUBMENU,
  MANAGE_SOFTWARE_SUBSCRIPTIONS_ROUTE,
  MARKET_DATA_SUBSCRIPTIONS_ROUTE,
} from '../ProfileManagement/Helper/constants'
import { useSubscriptionsSlice } from '../SoftwareSubscriptions/Slice/subscriptions.slice'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-community/dist/styles/ag-grid.css'
import CustomerSearch from '../CustomerSearch/customerSearch'
import { usePersonalInfoSlice } from '../PersonalInfo/Slice/personalInfo.slice'
import { useMarketDataSlice } from '../MarketDataSubsciptions/Slice/market-data.slice'
import { MarketDataPage } from '../MarketDataSubsciptions/Model'
import LanguageSelector from 'app/shared/localization/component/localization-selector'

export const AppLayout = () => {
  const dispatch = useDispatch()
  const subscriptionActions = useSubscriptionsSlice().actions
  const personalInfoSlice = usePersonalInfoSlice().actions
  const marketDataActions = useMarketDataSlice().actions
  const user = useSelector(selectLoggedInUser)
  const isBroker = useSelector(selecteUserIsBroker)
  const location = useCurrentLocation()

  useEffect(() => {
    if (!location.includes(MANAGE_SOFTWARE_SUBSCRIPTIONS_ROUTE)) {
      dispatch(subscriptionActions.saveTermsState(false))
    }
    if (!location.includes(MARKET_DATA_SUBSCRIPTIONS_ROUTE)) {
      dispatch(marketDataActions.setTermsAccepted(false))
      dispatch(marketDataActions.setMarketDataPageToRender(MarketDataPage.CertOrExchange))
      dispatch(marketDataActions.resetExchangePage())
    }
  }, [location])

  useEffect(() => {
    dispatch(personalInfoSlice.getPersonalDetailInfo())
    dispatch(personalInfoSlice.getAccountDetailsUpdate())
  }, [])

  return (
    <>
      {!isMono && <ButtonAppBar />}
      <div className={styles.appWidth}>
        {user && (
          <div className={styles.layoutColumns}>
            <div id="firstCol" className={styles.firstCol}>
              <div className={styles.desktopNav}>
                <LanguageSelector />
                <NavigationPanel />
              </div>
              <div className={styles.mobileNav}>
                <LanguageSelector />
                <NavigationPanelMobile />
              </div>
            </div>
            <div id="secondCol" className={styles.secondCol}>
              {isBroker &&
                FUTURES_TRADING_SOFTWARE_SUBMENU.some(
                  x => `${x.linkUrl}` === location.replace('/', '')
                ) && <CustomerSearch />}
              <Outlet />
            </div>
          </div>
        )}
      </div>
      <footer style={{ marginTop: '10px' }}></footer>
    </>
  )
}

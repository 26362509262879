import React from 'react'
import './navigationPanelSub.scss'
import styles from './navigationPanel.module.scss'
import { useSelector } from 'react-redux'
import { List, ListItem, MenuItem, Select } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useCurrentLocation } from 'app/shared/hooks/useCurrentLocation'
import { selectEnabledNavTabs } from 'app/authorization/Slice/auth-selectors'
import { translate } from 'app/shared/localization/helper/localization-helper'

const NavigationPanelMobile = () => {
  const profileNavItems = useSelector(selectEnabledNavTabs)
  const navigate = useNavigate()
  const location = useCurrentLocation()
  const hasFuturesTradingTabs = profileNavItems.some(x => x.isFuturesTradingTab)
  const updateSelectedLink = (value: string) => {
    const currentNavItem = profileNavItems.find(x => x.linkName === value)
    navigate(`/${currentNavItem.linkUrl}`)
  }

  const getSelectedValue = () => {
    const currentNavItem = profileNavItems.find(x => x.linkUrl === location.replace('/', ''))
    if (currentNavItem && currentNavItem.linkName) {
      return currentNavItem.linkName
    }
    return ''
  }
  return (
    <div className={styles.space}>
      <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItem style={{ paddingLeft: 0 }}>
          <Select
            value={getSelectedValue()}
            onChange={event => updateSelectedLink(event.target.value)}
            variant="outlined"
            style={{ minWidth: '400px' }}
          >
            <MenuItem disabled style={{ opacity: 1 }}>
              Profile
            </MenuItem>
            {profileNavItems.reduce((a, c, i) => {
              if (!c.isFuturesTradingTab) {
                a.push(
                  <MenuItem
                    key={i}
                    value={c.linkName ? c.linkName : ''}
                    style={{ paddingLeft: '35px' }}
                  >
                    {translate(c.translateKey, c.linkName, 'Headers')}
                  </MenuItem>
                )
              }
              return a
            }, [])}
            {hasFuturesTradingTabs && (
              <MenuItem disabled style={{ opacity: 1 }}>
                {translate('t__FuturesTradingSoftware', 'Futures Trading Software', 'Headers')}
              </MenuItem>
            )}
            {profileNavItems.reduce((a, c, i) => {
              if (c.isFuturesTradingTab) {
                a.push(
                  <MenuItem
                    key={i}
                    value={c.linkName ? c.linkName : ''}
                    style={{ paddingLeft: '35px' }}
                  >
                    {translate(c.translateKey, c.linkName, 'Headers')}
                  </MenuItem>
                )
              }
              return a
            }, [])}
          </Select>
        </ListItem>
      </List>
    </div>
  )
}
export default NavigationPanelMobile

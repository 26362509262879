import React, { useEffect } from 'react'
import { signoutRedirectCallback } from './user-service'
import { useNavigate } from 'react-router-dom'
import { Spinner } from './../components/ReusableComponents/spinner'

function SignoutOidc() {
  const LOGIN = '/login'
  const navigate = useNavigate()
  useEffect(() => {
    async function signoutAsync() {
      await signoutRedirectCallback()
      navigate(LOGIN)
    }
    signoutAsync()
  }, [navigate])

  return <Spinner />
}

export default SignoutOidc

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import styles from './contactSupport.module.scss'
import Button from '@mui/material/Button'
import { TicketData } from './Model/contactSupport.model'
import { useForm, SubmitHandler, Control, useWatch } from 'react-hook-form'
import {
  selectIsUserGainCustomerFetchStatus,
  selectLoggedInUser,
  selecteUserIsBroker,
} from 'app/authorization/Slice/auth-selectors'
import { useContactSupportSlice } from './Slice/contactSupport.slice'
import { contactSupportModalState, getSupportTicketStatus } from './Slice/contactSupport.selectors'
import { ProcessingStatus } from 'app/shared/models/models'
import { Alert, Snackbar } from '@mui/material'
import { getAlertSeverity } from 'app/shared/shared.constants'
import {
  selectUserAccountsData,
  selectUserAccountsDataFetchStatus,
} from '../SoftwareSubscriptions/Slice/subscriptions.selectors'
import { SupportSearch } from './components/select-search-dropdown'
import { useSubscriptionsSlice } from '../SoftwareSubscriptions/Slice/subscriptions.slice'
import { UserAccounts } from '../SoftwareSubscriptions/Model'
import { UserFetchStatus } from 'app/authorization/Models/user-fetch-status.enum'
import { translate } from 'app/shared/localization/helper/localization-helper'

const theme = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '540px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}

function IsolateReRender({ control }: { control: Control<TicketData> }) {
  const file = useWatch({
    control,
    name: 'attachment',
  })

  return <div className={styles.fileDiv}>{file ? `File Name: ${file[0].name}` : ''}</div>
}

export default function ContactSupport() {
  const [open, isOpen] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [selectedUserAccount, setSelectedUserAccount] = useState<UserAccounts | null>(null)
  const { register, handleSubmit, control, reset } = useForm<TicketData>()
  const dispatch = useDispatch()
  const { actions } = useContactSupportSlice()
  const subActions = useSubscriptionsSlice().actions
  const modalState = useSelector(contactSupportModalState)
  const user = useSelector(selectLoggedInUser)
  const getSupportStatus: number = useSelector(getSupportTicketStatus)
  const userAccountsFetchStatus = useSelector(selectUserAccountsDataFetchStatus)
  const userAccounts = useSelector(selectUserAccountsData)
  const isBroker = useSelector(selecteUserIsBroker)
  const userFetchStatus = useSelector(selectIsUserGainCustomerFetchStatus)
  const getSelectedUserAccount = (selectedAccount: UserAccounts) => {
    const userAccount = userAccounts?.accounts?.find(
      x => x.accountNumber === selectedAccount.accountNumber
    )
    setSelectedUserAccount(userAccount)
  }
  useEffect(() => {
    if (getSupportStatus === ProcessingStatus.COMPLETED) {
      setAlertOpen(true)
      closeModal()
    } else if (
      getSupportStatus === ProcessingStatus.ERROR ||
      getSupportStatus === ProcessingStatus.PENDING
    ) {
      setAlertOpen(true)
    }
  }, [getSupportStatus])

  useEffect(() => {
    if (modalState) {
      isOpen(modalState)
    }
  }, [modalState])

  useEffect(() => {
    if (!open) {
      reset()
    }
  }, [open])

  useEffect(() => {
    if (
      userAccountsFetchStatus === ProcessingStatus.RESET &&
      userFetchStatus === UserFetchStatus.Loaded
    ) {
      if (!isBroker) {
        dispatch(subActions.getUserAccounts())
      }
    }
  }, [userFetchStatus])

  const SupportSearchMemo = React.useMemo(() => {
    if (userAccounts && userAccounts.accounts !== null && userAccounts.accounts.length > 0) {
      return <SupportSearch getSelectedUserAccount={getSelectedUserAccount} />
    }
  }, [userAccountsFetchStatus])
  const closeModal = () => {
    isOpen(false)
    dispatch(actions.resetSupportTicket)
    dispatch(actions.setShowModalStatus(false))
  }

  const onSubmit: SubmitHandler<TicketData> = data => onSend(data)

  const onSend = (data: TicketData) => {
    if (selectedUserAccount) {
      data.accountNumber = selectedUserAccount.accountNumber
      data.whiteLabel = selectedUserAccount.whiteLabelId
    }
    dispatch(actions.sendSupportTicket(data))
  }

  const getUILabel = () => {
    if (getSupportStatus === ProcessingStatus.PENDING) {
      return 'Processing Request.'
    }

    if (
      getSupportStatus === ProcessingStatus.PENDING ||
      getSupportStatus === ProcessingStatus.RESET
    ) {
      return ''
    }

    if (getSupportStatus === ProcessingStatus.ERROR) {
      return 'An error occurred in sending the email.'
    }

    return 'Email sent.'
  }

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="contact-support-modal"
        aria-describedby="support-modal"
        className={''}
      >
        <Box sx={theme} className={styles.boxRoot}>
          <div className={styles.boxBody}>
            <Typography id="modalTitle" variant="h6" component="h6" className={styles.title}>
              {translate('t__Support', 'Support', 'Headers')}
            </Typography>
            <Typography id="fromfield" variant="subtitle1" component="h6" className={styles.from}>
              {translate('t__From', 'From', 'FieldNames')}
            </Typography>
            <Typography
              id="userfield"
              variant="subtitle1"
              component="h6"
              className={styles.userInfo}
            >
              {`${user?.profile.name} (${user?.profile.email})`}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.htmlForm}>
              {SupportSearchMemo}
              <label htmlFor="subject" className={styles.inputLabel}>
                {translate('t__Subject', 'Subject', 'FieldNames')}
                <div style={{ color: 'red', marginLeft: '2px' }}>&#42;</div>
              </label>
              <input
                id="subject"
                {...register('subject', { required: true })}
                className={styles.inputForm}
              />
              <label htmlFor="body" className={styles.inputLabel}>
                {translate('t__Description', 'Description', 'FieldNames')}
                <div style={{ color: 'red', marginLeft: '2px' }}>&#42;</div>
              </label>
              <textarea
                id="body"
                {...register('body', { required: true })}
                className={styles.inputFormDescription}
              />
              {/* <label htmlFor="upload" className={styles.inputLabel}>
                Attachment
              </label>
              <label className={styles.fileUploadLabel}>
                <input
                  name="upload"
                  {...register('attachment', { required: false })}
                  type="file"
                  id="fileUpload"
                />
                <span className={styles.spanFileUpload}>
                  <FileUploadOutlinedIcon />
                  UPLOAD DOCUMENT
                </span>
              </label> */}
              <IsolateReRender control={control} />
              <div className={styles.buttonGrp}>
                <Button
                  variant="outlined"
                  color="inherit"
                  style={{ marginRight: '16px' }}
                  onClick={() => closeModal()}
                >
                  {translate('t__Cancel', 'CANCEL', 'Common')}
                </Button>
                <Button type="submit" variant="contained">
                  {translate('t__Send', 'SEND', 'Common')}
                </Button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => setAlertOpen(false)}>
        <Alert
          onClose={() => setAlertOpen(alertOpen)}
          severity={getAlertSeverity(getSupportStatus)}
          sx={{ width: '100%' }}
        >
          {getUILabel()}
        </Alert>
      </Snackbar>
    </>
  )
}

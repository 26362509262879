import React, { useEffect, useState } from 'react'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import AuthProvider from './authorization/auth-provider'
import { useDispatch, useSelector, useStore } from 'react-redux'
import {
  selectIsUserGainCustomerFetchStatus,
  selectUserStatus,
} from './authorization/Slice/auth-selectors'
import Login from './authorization/login'
import SigninOidc from './authorization/signin-oidc'
import SignoutOidc from './authorization/signout-oidc'
import { UserFetchStatus } from './authorization/Models/user-fetch-status.enum'
import Logout from './authorization/logout'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { OktaSecurity, oktaAuth } from './authorization/okta'
import { loadUserFromStorage } from './authorization/user-service'
import {
  ACCOUNT_DETAILS_ROUTE,
  DOWNLOAD_SOFTWARE_ROUTE,
  LOGIN,
  LOGOUT,
  MANAGE_DEMO_ACCOUNT_ROUTE,
  MANAGE_SOFTWARE_SUBSCRIPTIONS_ROUTE,
  MARKET_DATA_SUBSCRIPTIONS_ROUTE,
  PERSONAL_DETAILS_ROUTE,
  POST_LOGOUT_URI,
  REDIRECT_URI,
  THIRD_PARTY_PLATFORMS_ROUTE,
} from './components/ProfileManagement/Helper/constants'
import { I18nextProvider, useTranslation } from 'react-i18next'
import i18next from './initialization/i18n'
import { AppLayout } from './components/AppLayout/appLayout'
import { PersonalInformationPage } from './routing/pages/personal-information/loadable'
import { AccountInformationPage } from './routing/pages/account-information/loadable'
import { SoftwareDownloadPage } from './routing/pages/software-download/loadable'
import { ManageSoftwareSubscriptionPage } from './routing/pages/manage-software-subscription/loadable'
import { ThirdPartyPlatformsPage } from './routing/pages/third-party-platforms/loadable'
import { ManageDemoPage } from './routing/pages/manage-demo/loadable'
import { MarketDataSubscriptionsPage } from './routing/pages/market-data-subscriptions/loadable'
import { useAuthSlice } from './authorization/Slice'
import { useLocalizationSlice } from './shared/localization/slice/localization.slice'

export default function App() {
  const dispatch = useDispatch()
  const authActions = useAuthSlice().actions
  const localizationActions = useLocalizationSlice()
  const userStatus = useSelector(selectUserStatus)
  const isGainUserStatus = useSelector(selectIsUserGainCustomerFetchStatus)
  const { ready } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const store = useStore()
  const enableMarketDataSubs = process.env.REACT_APP_SHOW_MARKET_DATA === 'true'

  const [languageLoaded, setLanguageLoaded] = useState(false)

  const { i18n } = useTranslation()
  useEffect(() => {
    i18n.services.backendConnector.backend.getLanguages((err: unknown, ret: object) => {
      if (err) {
        setLanguageLoaded(true)
        return
      }
      setLanguageLoaded(true)
      dispatch(localizationActions.actions.setAvailableLanguages(ret))
      if (
        process.env.REACT_APP_ENABLE_LOCALIZATION === 'false' ||
        !localStorage.getItem('i18nextLng')
      ) {
        i18n.changeLanguage('en')
      } else {
        i18n.changeLanguage(localStorage.getItem('i18nextLng'))
      }
    })
  }, [])

  useEffect(() => {
    loadUserFromStorage(store)
  }, [store])

  useEffect(() => {
    if (location.pathname === '/' && userStatus === UserFetchStatus.Loaded) {
      navigate(`/${PERSONAL_DETAILS_ROUTE}`)
    }
  }, [location, userStatus])

  useEffect(() => {
    if (
      isGainUserStatus === UserFetchStatus.LoadingFromStorage &&
      userStatus === UserFetchStatus.Loaded
    ) {
      dispatch(authActions.getIsGainUser())
    }
  }, [isGainUserStatus, userStatus])

  return (
    <div className="profileManagement">
      <I18nextProvider i18n={i18next}>
        {userStatus === UserFetchStatus.LoadingFromStorage || !ready || !languageLoaded ? (
          <div>Loading...Please wait.</div>
        ) : (
          <OktaSecurity>
            <AuthProvider store={store} authClient={oktaAuth}>
              <Routes>
                <Route path={LOGIN} element={<Login userStatus={userStatus} />} />
                <Route path={REDIRECT_URI} element={<SigninOidc />} />
                <Route path={POST_LOGOUT_URI} element={<SignoutOidc />} />
                <Route path={LOGOUT} element={<Logout />} />
                <Route path={'/'} element={<AppLayout />}>
                  <Route path={PERSONAL_DETAILS_ROUTE} element={<PersonalInformationPage />} />
                  <Route path={ACCOUNT_DETAILS_ROUTE} element={<AccountInformationPage />} />
                  <Route path={DOWNLOAD_SOFTWARE_ROUTE} element={<SoftwareDownloadPage />} />
                  {enableMarketDataSubs && (
                    <Route
                      path={MARKET_DATA_SUBSCRIPTIONS_ROUTE}
                      element={<MarketDataSubscriptionsPage />}
                    />
                  )}
                  <Route
                    path={MANAGE_SOFTWARE_SUBSCRIPTIONS_ROUTE}
                    element={<ManageSoftwareSubscriptionPage />}
                  />
                  <Route path={THIRD_PARTY_PLATFORMS_ROUTE} element={<ThirdPartyPlatformsPage />} />
                  <Route path={MANAGE_DEMO_ACCOUNT_ROUTE} element={<ManageDemoPage />} />
                </Route>
              </Routes>
            </AuthProvider>
          </OktaSecurity>
        )}
      </I18nextProvider>
    </div>
  )
}

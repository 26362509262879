import ApiService from 'app/components/ProfileManagement/Helper/api-service'
import { OfficeData, TicketData } from '../Model/contactSupport.model'
import { POST_SUPPORT_EMAIL } from 'app/components/ProfileManagement/Helper/constants'

export const getOfficeCodeForSelectedAccount = async (
  accountNumber: string
): Promise<OfficeData[]> => {
  const BASE_URL = process.env.REACT_APP_OFFICE_CODE_API
  const officeCodeURL = `${BASE_URL}?$apply=groupby((Office, AccountNumber))&$filter=(AccountNumber eq '${accountNumber}')`
  const officeCode = await ApiService.get(officeCodeURL)

  if (!officeCode.data?.length) {
    return []
  }

  const officeCodesData = officeCode.data
  return officeCodesData
}

export const SendContactSupportEmailAPI = async (emailData: TicketData): Promise<unknown> => {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const ENDPOINT = `${BASE_URL}${POST_SUPPORT_EMAIL}`

  const formData = new FormData()
  formData.append('subject', emailData.subject)
  formData.append('body', emailData.body)

  const ipResponse = await fetch('https://ipapi.co/json/')
  const data = await ipResponse.json()
  if (data.ip) {
    formData.append('ipAddress', data.ip)
  }

  if (emailData.officeCode) {
    formData.append('officeCode', emailData.officeCode.toString())
  }
  if (emailData.whiteLabel) {
    formData.append('whiteLabelId', emailData.whiteLabel.toString())
  }
  if (emailData.accountNumber) {
    formData.append('accountNumber', emailData.accountNumber.toString())
  }
  if (emailData.attachment && emailData.attachment.length > 0) {
    const files = emailData.attachment ? [...emailData.attachment] : []
    formData.append('attachment', files[0])
  }

  const response = await ApiService.post(ENDPOINT, formData).catch(function (error) {
    throw error
  })
  return response
}

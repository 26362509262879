import ApiService from 'app/components/ProfileManagement/Helper/api-service'
import { GetCustomerOfBrokerResponse } from '../Model/customerSearch.model'
import { GET_CUSTOMER_LIST } from 'app/components/ProfileManagement/Helper/constants'

export const getCustomerListAPI = async (): Promise<GetCustomerOfBrokerResponse> => {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const ENDPOINT = `${BASE_URL}${GET_CUSTOMER_LIST}`
  return ApiService.get(ENDPOINT).catch(err => {
    throw err
  })
}

export enum CustomerListFetchStatus {
  Initial,
  Loading,
  Success,
  Error,
}

export interface CustomerOfBroker {
  id: number
  cdsId: string
  lastName: string
  firstName: string
  customerEmail: string
  accountName: string
  accountNumber: string
  demo: string
  customerUserName: string
}

export interface GetCustomerOfBrokerResponse {
  data: []
}

export interface CustomerOfBrokerState {
  customerList: CustomerOfBroker[]
  customerListStatus: CustomerListFetchStatus
  selctedCustomer: CustomerOfBroker | null
}

export const initialState: CustomerOfBrokerState = {
  customerList: [],
  customerListStatus: CustomerListFetchStatus.Initial,
  selctedCustomer: null,
}

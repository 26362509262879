import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-locize-backend'

const locizeOptions = {
  projectId: '3443ce05-71fe-419e-973a-63568937b1a9', //Project Id found from the Locize webpage
  apiKey: '5f81e4bf-a50a-4dec-9612-8cb0e81027b7', // The API key should only be used in development, not in production. You should not expose your apps API key to production!!!
  referenceLng: 'en',
  version: 'Development', //Version is the locize branch of the project
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    saveMissing: true,
    interpolation: {
      escapeValue: false,
    },
    backend: locizeOptions,
    ns: ['Headers', 'Common', 'FieldNames', 'Messages'],
    defaultNS: 'common',
  })

export default i18n

/* eslint-disable @typescript-eslint/no-explicit-any */
import ApiService from 'app/components/ProfileManagement/Helper/api-service'
import {
  Account,
  AccountAddress,
  PersonalInformation,
  UpdateContactAccountRequest,
  UpdateContactResponse,
  AccountDetailsUpdates,
} from '../Model'
import { SimpleIdName } from 'app/shared/models/models'
import {
  GET_ACCOUNTS_PROXY,
  GET_ACCOUNT_ADDRESSES_PROXY,
  GET_PERSONAL_INFO_CNTRY_PROXY,
  GET_PERSONAL_INFO_PROXY,
  UPDATE_PERSONAL_DETAILS,
  UPDATE_PROFILE_STATUS,
} from 'app/components/ProfileManagement/Helper/constants'

export const GetPersonalInfoAPI = async (): Promise<PersonalInformation> => {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const ENDPOINT = `${BASE_URL}${GET_PERSONAL_INFO_PROXY}`
  const response = await ApiService.get(ENDPOINT).catch(function (error) {
    throw error
  })
  return response?.data
}

export const GetAccountDetailsAPI = async (applicationIds: string[]): Promise<Account[]> => {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const ENDPOINT = `${BASE_URL}${GET_ACCOUNTS_PROXY}`
  const data = await Promise.all(
    applicationIds.map(appId =>
      ApiService.get(`${ENDPOINT}?applicationId=${appId}`)
        .then(r => r.data)
        .catch(function (error) {
          throw error
        })
    )
  )

  return data.filter((x: Account) => x.companyType !== null && x.primaryAccountId !== null)
}

export const GetAccountAddressesAPI = async (account: Account): Promise<AccountAddress> => {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const ENDPOINT = `${BASE_URL}${GET_ACCOUNT_ADDRESSES_PROXY}`

  const response = await ApiService.get(`${ENDPOINT}?applicationId=${account.applicationId}`).catch(
    function (error) {
      throw error
    }
  )

  const aa: AccountAddress = {
    accountId: account?.accountId ?? '',
    applicationId: account.applicationId,
    addresses: response?.data,
  }

  return aa
}

export const postSavePersonalInfoAPI = async (
  personalInfo: UpdateContactAccountRequest
): Promise<UpdateContactResponse> => {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const ENDPOINT = `${BASE_URL}${UPDATE_PERSONAL_DETAILS}`
  const response = await ApiService.post(ENDPOINT, personalInfo).catch(function (error) {
    throw error
  })
  return response?.data
}

export const postCountriesAPI = async (countries: unknown): Promise<SimpleIdName[]> => {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const ENDPOINT = `${BASE_URL}${GET_PERSONAL_INFO_CNTRY_PROXY}`
  const response = await ApiService.post(ENDPOINT, countries).catch(function (error) {
    throw error
  })
  return response?.data
}

export const getAccountUpdateStatus = async (): Promise<AccountDetailsUpdates> => {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const ENDPOINT = `${BASE_URL}${UPDATE_PROFILE_STATUS}`
  const response = await ApiService.get(ENDPOINT).catch(function (error) {
    throw error
  })
  return response.data as AccountDetailsUpdates
}

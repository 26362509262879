import { ProcessingStatus } from './models/models'

export const formatUSPhone = (phone: string): string => {
  const cleaned = ('' + phone).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = match[1] ? '+1 ' : '',
      number = [intlCode, match[2], '-', match[3], '-', match[4]].join('')
    return number
  }
  return phone
}

export const validatePhone = (phone: string): boolean => {
  const cleaned = ('' + phone).replace(/\D/g, '')
  return cleaned.length >= 10 && cleaned.length <= 11
}

export const getAlertSeverity = (enableStatus: number) => {
  if (enableStatus === ProcessingStatus.PENDING) {
    return 'info'
  }

  if (enableStatus === ProcessingStatus.ERROR) {
    return 'error'
  }

  return 'success'
}

export const removeSpecialCharacters = /[^a-zA-Z0-9]/g

import axios, { AxiosResponse, AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios'

export const ApiService: AxiosInstance = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Accept-Language': localStorage.getItem('i18nextLng') ?? 'en-US',
  },
})

let authToken = ''

export const setAuthorizationToken = (token: string) => {
  authToken = `Bearer ${token}`
}

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  if (!config.headers.Authorization) {
    config.headers.Authorization = authToken
  }
  if (config.headers?.resetHeader) {
    config.headers = {}
  }
  config.headers['Accept-Language'] = localStorage.getItem('i18nextLng') ?? 'en'

  return config
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error)
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error)
}

ApiService.interceptors.request.use(onRequest, onRequestError)
ApiService.interceptors.response.use(onResponse, onResponseError)

export default ApiService

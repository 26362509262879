import { KeyForString } from '../Model'

export const FIRST_NAME = 'First Name'
export const LAST_NAME = 'Last Name'
export const ADDRESS = 'Address'
export const CITY = 'City'
export const COUNTRY = 'Country'
export const COUNTRY_ID = 'countryId'
export const STATE_REGION_PROVINCE = 'State/Province/Region'
export const STATE_REGION_PROVINCE_ID = 'stateId'
export const ZIP_POSTAL_CODE = 'Zip/Postal Code'

export const CREDIT_CARD_NUMBER = 'Credit Card Number'
export const CREDIT_CARD_NUMBER_MASKED = 'ccNumber'
export const CARD_EXPIRATION_DATE = 'Expiration Date'
export const CARD_CVV = 'CVV'
export const CARD_CVV_MASKED = 'cvvMasked'
export const CARD_TYPE = 'Type'

export const billingInfoKeys: KeyForString[] = [
  { id: 1, name: FIRST_NAME, translationKey: 't__FirstName' },
  { id: 2, name: LAST_NAME, translationKey: 't__LastName' },
  { id: 3, name: ADDRESS, translationKey: 't__Address' },
  { id: 4, name: CITY, translationKey: 't__City' },
  { id: 5, name: COUNTRY, translationKey: 't__Country' },
  { id: 6, name: STATE_REGION_PROVINCE, translationKey: 't__StateProvReg' },
  { id: 7, name: ZIP_POSTAL_CODE, translationKey: 't__ZipPostalCode' },
]

export const ccInfoKeys: KeyForString[] = [
  { id: 1, name: CREDIT_CARD_NUMBER, translationKey: 't__CreditCardNumber' },
  { id: 2, name: CARD_EXPIRATION_DATE, translationKey: 't__CreditCardExp' },
  { id: 3, name: CARD_CVV, translationKey: 't__CreditCardCode' },
  { id: 4, name: CARD_TYPE, translationKey: 't__CreditCardType' },
]

export const LAST_DAY_WARNING =
  'Please return to this page any time after the first of the month to manage your market data subsciptions.'
export const LAST_THREE_DAY_WARNING =
  'The end of the month is approaching. As a reminder, market data subsciptions are not prorated. Please confirm you wish to subscribe to market data. Otherwise, you may wait for the first calendar day to subscribe.'

import { PayloadAction } from '@reduxjs/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'
import { ProcessingStatus } from 'app/shared/models/models'
import {
  ActiveSubscriptionAPI,
  defaultPendingRequst,
  initialState,
  NewSubscriptionDataObj,
  SubscriptionsData,
  UserAccountsResponse,
} from '../Model/subscriptions.model'
import { subscriptionsSaga } from './subscriptions.saga'
import { createSlice } from 'store/@reduxjs/toolkit'

const slice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    getSubscriptionData() {},
    getUserAccounts(state) {
      state.userAccountFetchStatus = ProcessingStatus.PENDING
    },
    resetUserAccounts(state) {
      state.userAccountFetchStatus = ProcessingStatus.RESET
      state.userAccountResponse = null
    },
    saveTermsState(state, action: PayloadAction<boolean>) {
      state.termsModal = action.payload
    },
    saveSoftwareSubscriptionData(state, action: PayloadAction<ActiveSubscriptionAPI>) {
      state.subscriptionWarning = action.payload.warning
      state.subscriptionsData = action.payload.software
    },
    subscriptionsError(state, action: PayloadAction<string | null>) {
      state.subscriptionError = action.payload
      state.subscriptionsData = []
    },
    resetSubscriptionsError(state) {
      state.subscriptionError = null
    },
    saveUserAccounts(state, action: PayloadAction<UserAccountsResponse>) {
      state.userAccountResponse = action.payload
      state.userAccountFetchStatus = ProcessingStatus.COMPLETED
    },
    showChangeSubscriptionPage(state, action: PayloadAction<boolean>) {
      state.showChangeSubscriptionsPage = action.payload
    },
    softwareSubscriptionsUpdateObj(state, action: PayloadAction<NewSubscriptionDataObj>) {
      state.newSubscriptionDataObj = action.payload
    },
    saveNewSubscriptionChange(state, action: PayloadAction<NewSubscriptionDataObj>) {
      state.subscriptionProcessingStatus = ProcessingStatus.PENDING
    },
    saveChangedSubscription(state, action: PayloadAction<SubscriptionsData[]>) {
      state.storedChangedSubscriptionData = action.payload
    },
    newSubscriptionProcessingStatus(state, action: PayloadAction<ProcessingStatus>) {
      state.subscriptionProcessingStatus = action.payload

      if (action.payload === ProcessingStatus.COMPLETED) {
        state.subscriptionWarning = defaultPendingRequst
      }
    },
  },
})

export const { actions: SubscriptionsActions, reducer } = slice

export const useSubscriptionsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  useInjectSaga({ key: slice.name, saga: subscriptionsSaga })
  return { actions: slice.actions }
}

import { format } from 'date-fns'
import { isMono } from '@mono/api'
import { Location } from 'react-router'

export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export const formatISODate = (dateToConvert: Date) => {
  return format(new Date(dateToConvert), 'yyyy-MM-dd')
}

export const currentRoute = (location: Location, searchParams: URLSearchParams) => {
  if (isMono) {
    return searchParams.get('route')
  } else {
    return location.pathname
  }
}

export const validateEmail = (email: string) => {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true
  }
  //email is invalid
  return false
}

export const numberValidation = (value: string) => {
  if (/^\d+$/.test(value)) {
    return true
  }
  //contains something other than numbers
  return false
}

export const doesContainLetters = (value: string) => {
  if (/^[a-zA-Z]+$/.test(value)) {
    return true
  }
  //contains something other than alphabetical letters
  return false
}

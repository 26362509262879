import { ILinkDetails } from 'app/components/NavigationPanel/Interfaces/navigationPanelInterface'
import { UserFetchStatus } from './user-fetch-status.enum'
import { User } from './user-model'
export interface AuthState {
  userFetchStatus?: UserFetchStatus
  user: User | null
  userIsGainCustomer: boolean | null
  userIsGainCustomerFetchStatus: UserFetchStatus
  userIsBroker: boolean | null
  userNavItems: ILinkDetails[]
}

export const initialState: AuthState = {
  userFetchStatus: UserFetchStatus.LoadingFromStorage,
  user: null,
  userIsGainCustomer: null,
  userIsGainCustomerFetchStatus: UserFetchStatus.LoadingFromStorage,
  userIsBroker: null,
  userNavItems: [],
}

import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { useInjectReducer } from 'redux-injectors'
import { initialState } from '../model/localization.state'

const slice = createSlice({
  name: 'localization',
  initialState,
  reducers: {
    setAvailableLanguages(state, action: PayloadAction<object>) {
      state.AvailableLanguages = action.payload
    },
    setSelectedLanguage(state, action: PayloadAction<string>) {
      state.SelectedLanguage = action.payload
    },
  },
})

export const { actions: accountsFeatureFlagActions, reducer } = slice

export const useLocalizationSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  return { actions: slice.actions }
}

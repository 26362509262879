import React from 'react'
import { useOktaAuth, Security } from '@okta/okta-react'
import { OktaAuth, OktaAuthOptions, toRelativeUrl } from '@okta/okta-auth-js'

const config: OktaAuthOptions = {
  issuer: process.env.REACT_APP_OKTA_ISSUER || 'https://localhost:3000/oauth2/123456',
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI,
  postLogoutRedirectUri: process.env.REACT_APP_OKTA_POST_LOGOUT_URI,
  responseType: 'code',
  scopes: ['openid', 'profile', 'email', 'offline_access', 'Identity.Api', 'OTCApi'],
  pkce: true,
  tokenManager: {
    storage: 'localStorage',
    autoRenew: true,
  },
}

const oktaAuth = new OktaAuth(config)

const OktaSecurity = (props: React.PropsWithChildren<{ config?: OktaAuthOptions }>) => {
  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    window.location.replace(toRelativeUrl(originalUri || '/', window.location.origin))
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {props.children}
    </Security>
  )
}

export { OktaSecurity, useOktaAuth, oktaAuth }

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TextField from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { CustomerListFetchStatus, CustomerOfBroker } from '../Model'
import { useCustomerListSlice } from '../Slice/customerSearch.slice'
import Box from '@mui/material/Box'
import {
  customerOfBrokerList,
  customerOfBrokerListStatus,
  customerOfBrokerSelected,
} from '../Slice/customerSearch.selector'
import { useThirdPartySlice } from 'app/components/ThirdPartyPlatforms/Slice/thirdparty.slice'
import { useSubscriptionsSlice } from 'app/components/SoftwareSubscriptions/Slice/subscriptions.slice'
import { translate } from 'app/shared/localization/helper/localization-helper'

const SearchInputBox: React.FC = () => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const { actions } = useCustomerListSlice()
  const thirdPartyActions = useThirdPartySlice().actions
  const accountActions = useSubscriptionsSlice().actions
  const selectedCustomer = useSelector(customerOfBrokerSelected)
  const customers = useSelector(customerOfBrokerList)
  const customersStatus = useSelector(customerOfBrokerListStatus)
  const filterOptions = createFilterOptions({
    stringify: (option: CustomerOfBroker) =>
      option.accountNumber + option.accountName + option.customerEmail + option.demo,
  })

  return (
    <>
      <Autocomplete
        id="customer-search"
        sx={{
          '.MuiAutocomplete-popupIndicator[title="Open"]': {
            display: 'none',
          },
        }}
        disabled={customersStatus !== CustomerListFetchStatus.Success}
        options={customers}
        open={open}
        onInputChange={(e, value) => {
          setOpen(e !== null && value.length > 1)
        }}
        onClose={() => setOpen(false)}
        filterOptions={filterOptions}
        value={selectedCustomer || null}
        getOptionLabel={(option: CustomerOfBroker) => option.accountName}
        renderOption={(props, option: CustomerOfBroker) => (
          <li style={{ width: '100%' }} {...props} key={`${option.id}${option.accountName}`}>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <div>
                {option.accountName} ({option.accountNumber} {option.demo})
                <div>{option.customerEmail}</div>
              </div>
            </Box>
          </li>
        )}
        isOptionEqualToValue={(option: CustomerOfBroker, value: CustomerOfBroker) =>
          option.accountNumber === value.accountNumber &&
          option.accountName === value.accountName &&
          option.firstName === value.firstName &&
          option.lastName === value.lastName &&
          option.customerEmail === value.customerEmail &&
          option.demo === value.demo
        }
        onChange={(_e, newValue) => {
          const helper = newValue as unknown as CustomerOfBroker
          //when switching customers, need to clear any Third party messages for previous user.
          dispatch(thirdPartyActions.resetEnableThirdPartyClearAll())
          dispatch(actions.customerOfBrokerSelected(helper))
          dispatch(accountActions.resetUserAccounts())
        }}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={translate(
              't__BrokerSearchText',
              'Search for account number, name, or email',
              'Messages'
            )}
            inputProps={{ ...params.inputProps, autoComplete: 'off' }}
          />
        )}
      />
      <p></p>
    </>
  )
}

export default SearchInputBox

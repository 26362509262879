import React from 'react'
import Box from '@mui/material/Box'
import './navigationPanelSub.scss'
import styles from './navigationPanel.module.scss'
import { ILinkDetails } from './Interfaces/navigationPanelInterface'
import { useSelector } from 'react-redux'
import { List, ListItem, ListItemText } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import { useNavigate } from 'react-router-dom'
import { useCurrentLocation } from 'app/shared/hooks/useCurrentLocation'
import { selectEnabledNavTabs } from 'app/authorization/Slice/auth-selectors'
import { translate } from 'app/shared/localization/helper/localization-helper'

const NavigationPanel: React.FC = (): JSX.Element => {
  const profileNavItems = useSelector(selectEnabledNavTabs)
  const navigate = useNavigate()
  const location = useCurrentLocation()
  const updateSelectedLink = (value: ILinkDetails) => {
    navigate(`/${value.linkUrl}`)
  }
  const hasFuturesTradingTabs = profileNavItems.some(x => x.isFuturesTradingTab)
  return (
    <Box className={styles.box}>
      <div className={styles.space}>
        <List component="nav" aria-labelledby="nested-list-subheader">
          <>
            <ListItem className={'navPanelFuturesTradingSoftwareTitle'}>
              <ListItemButton
                sx={{ backgroundColor: 'white !important', paddingLeft: '0px' }}
                disableRipple={true}
                key={1}
              >
                <ListItemText className={'navPanelSmallTitle'}>
                  {translate('t__Profile', 'Profile', 'Headers')}
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <>
              {profileNavItems.reduce((a, c, i) => {
                if (!c.isFuturesTradingTab) {
                  a.push(
                    <ListItemButton
                      disableRipple={true}
                      key={i}
                      selected={location.includes(c.linkUrl)}
                      onClick={() => updateSelectedLink(c)}
                    >
                      <ListItemText className={'navPanelSubClassMenu'}>
                        {translate(c.translateKey, c.linkName, 'Headers')}
                      </ListItemText>
                    </ListItemButton>
                  )
                }
                return a
              }, [])}
            </>
          </>
          <>
            {hasFuturesTradingTabs && (
              <ListItem className={'navPanelFuturesTradingSoftwareTitle'}>
                <ListItemButton
                  sx={{ backgroundColor: 'white !important', paddingLeft: '0px' }}
                  disableRipple={true}
                  key={1}
                >
                  <ListItemText className={'navPanelSmallTitle'}>
                    {translate('t__FuturesTradingSoftware', 'Futures Trading Software', 'Headers')}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            )}
            <>
              {profileNavItems.reduce((a, c, i) => {
                if (c.isFuturesTradingTab) {
                  a.push(
                    <ListItemButton
                      disableRipple={true}
                      key={i}
                      selected={location.includes(c.linkUrl)}
                      onClick={() => updateSelectedLink(c)}
                    >
                      <ListItemText className={'navPanelSubClassMenu'}>
                        {translate(c.translateKey, c.linkName, 'Headers')}
                      </ListItemText>
                    </ListItemButton>
                  )
                }
                return a
              }, [])}
            </>
          </>
        </List>
      </div>
    </Box>
  )
}

export default NavigationPanel
